import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import IsVisible from 'react-is-visible';

function PoliticaPrivacidade() {
  return (
    <>
      <Helmet>
       <title>Política de Privacidade</title>
       <meta name="description" content="Política de Privacidade do Dr. Clube" />
       <link rel='canonical' href='/politicaprivacidade' />
    </Helmet>
    <div className="col p-5">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-13">
                    <div>
                    <h2>Política de Privacidade, Informações e tratamento de dados para parceiros</h2>
                    <br></br>
                    <br></br>
                    <h4>Objetivo</h4>
                    <br/>
                        <p>Nosso objetivo é fornecer uma plataforma confiável e eficaz que conecte profissionais de saúde e empresas que oferecem serviços de saúde a usuários em busca de cuidados de saúde de qualidade. Queremos facilitar o acesso a serviços de saúde confiáveis, permitindo que os usuários encontrem e agendem atendimentos de forma conveniente e segura. Além disso, nosso objetivo é proteger e respeitar a privacidade e a segurança dos dados dos usuários, garantindo uma experiência positiva e benéfica para todas as partes envolvidas. Estamos comprometidos em manter os mais altos padrões de qualidade, ética e transparência em todos os aspectos de nossos serviços, contribuindo assim para melhorar a saúde e o bem-estar da comunidade que servimos.</p>
                        <br/>
                    <h4>Quem somos</h4>
                    <br/>
                        <p>Somos uma equipe dedicada e comprometida que criou e mantém esta plataforma para conectar profissionais de saúde e empresas que oferecem serviços de saúde a usuários em busca de cuidados de saúde de qualidade. Nossa equipe é formada por indivíduos apaixonados pela melhoria do acesso à saúde e pela facilitação de uma experiência de busca e agendamento de serviços de saúde mais eficaz, conveniente e segura.</p>
                        <br/>
                        <p>Nosso compromisso é promover a confiabilidade, a transparência e a privacidade em todos os aspectos de nossos serviços. Acreditamos que a saúde é um aspecto fundamental da qualidade de vida, e nossa plataforma visa tornar mais fácil para as pessoas encontrarem os serviços de saúde de que precisam, quando precisam.</p>
                        <br/>
                        <p>Estamos empenhados em continuar aprimorando nossa plataforma, ouvindo os feedbacks de nossos usuários e parceiros, e mantendo os mais altos padrões éticos e de segurança para garantir uma experiência positiva para todos os envolvidos.</p>
                        <br/>
                        <p>Nossa missão é contribuir para a melhoria da saúde e do bem-estar de nossos usuários, conectando-os com profissionais e serviços de saúde confiáveis. Estamos entusiasmados em fazer parte dessa jornada e em ajudar a comunidade que servimos a alcançar uma vida mais saudável e plena.</p>
                        <br/>
                        <h4>1. Coleta de Informações</h4>
                        <br/>
                        <p>1.1 Informações Pessoais Fornecidas pelo Parceiro:</p>
                        <p>Ao se registrar ou usar nossos serviços, você pode fornecer informações pessoais, como:</p>
                           <p>1. Nome</p>
                           <p>2. Endereço de e-mail</p>
                           <p>3. Número de telefone</p>
                           <p>e outras informações profissionais, a fim de criar e gerenciar sua conta.</p>
                        <br/>
                        <p>1.2 Informações Profissionais ou do Estabelecimento Fornecidas pelo Parceiro:</p>
                        <p>Ao se registrar ou usar nossos serviços, você pode fornecer informações  Profissionais ou do Estabelecimento, como:</p>
                           <p>1. Razão Social</p>
                           <p>2. Cnpj</p>
                           <p>3. Número de telefone</p>
                           <p>Endereço para atendimento</p>
                           <p>Logo do Estabelecimeto</p>
                           <p>Imagens do Estabelecimento</p>
                           <p>Profissão e dados correlacionado</p>
                           <p>Horário de atendimento</p>
                           <p>e outras informações profissionais, a fim de criar e gerenciar seu perfil.</p>
                           <br/>
                        <p>1.3 Informações sobre Serviços de Saúde:</p>
                        <p>Os profissionais de saúde e empresas que oferecem serviços em nossa plataforma podem fornecer informações sobre os serviços que prestam, suas qualificações, horários de atendimento, convênios ofereridos, descrição, preços, imagens e outras informações relevantes.</p>
                        <br/>
                        <p>1.4 Informações de Uso:</p>
                        <p>Coletamos informações sobre como você utiliza nosso site e aplicativo, incluindo interações com perfis de profissionais de saúde, consultas realizadas, avaliações e outros dados de uso para melhorar a qualidade de nossos serviços.</p>
                        <br/>
                        <h4>2. Uso das Informações</h4>
                        <br/>
                        <p>2.1 Fornecimento de Serviços:</p>
                        <p>Utilizamos suas informações para facilitar a conexão entre usuários e profissionais de saúde, permitindo a busca e agendamento de serviços de saúde.</p>
                        <br/>
                        <p>2.2 Comunicações:</p>
                        <p>Podemos enviar-lhe comunicações relacionadas ao uso de nossos serviços, como confirmações de agendamento, atualizações e notificações relevantes.</p>
                        <br/>
                        <p>2.3 Melhorias e Personalização:</p>
                        <p>Utilizamos informações coletadas para melhorar nossos serviços, adaptar conteúdo e recomendações, além de aprimorar a experiência do usuário.</p>
                        <br/>
                        <p>2.4 Segurança:</p>
                        <p>Protegemos suas informações pessoais e monitoramos atividades suspeitas para garantir a segurança de nossa plataforma.</p>
                        <br/>
                        <h4>3. Compartilhamento de Informações</h4>
                        <br/>
                        <p>3.1 Com Profissionais de Saúde e Empresas:</p>
                        <p>Compartilhamos informações de perfil de usuário com profissionais de saúde e empresas para facilitar a prestação de serviços e agendamentos.</p>
                        <br/>
                        <p>3.2 Terceiros Parceiros:</p>
                        <p>Podemos compartilhar informações agregadas e anonimizadas com parceiros terceiros para análises e pesquisa, mas nunca compartilhamos informações pessoais sem seu consentimento.</p>
                        <br/>
                        <p>3.3 Cumprimento Legal:</p>
                        <p>Podemos divulgar informações quando necessário para cumprir obrigações legais ou responder a solicitações de autoridades governamentais.</p>
                        <br/>
                        <h4>4. Proteção de Dados e Segurança</h4>
                        <br/>
                        <p>Implementamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso não autorizado, uso indevido e divulgação não autorizada.</p>
                        <br/>
                        <h4>5. Seus Direitos</h4>
                        <br/>
                        <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Além disso, pode retirar o consentimento para o processamento de seus dados. Entre em contato conosco para exercer esses direitos.</p>
                        <br/>
                        <h4>6. Alterações na Política de Privacidade</h4>
                        <br/>
                        <p>Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão comunicadas através do site ou aplicativo. O uso continuado de nossos serviços após a alteração constitui sua aceitação das modificações.</p>
                        <br/>
                        <h4>7. Contato</h4>
                        <br/>
                        <p>Para perguntas, preocupações ou solicitações relacionadas à privacidade, entre em contato conosco através dos canais de suporte fornecidos em nosso site e aplicativo.</p>
                        <p>Esta Política de Privacidade reflete nosso compromisso em proteger sua privacidade e dados pessoais enquanto fornece uma plataforma para a busca de serviços de saúde confiáveis. Obrigado por escolher o Dr. Clube como seu recurso de saúde confiável.</p>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
           
            </>
            )}
            </IsVisible>
        </div>
    </div>
    </>
  )
}

export default PoliticaPrivacidade;