import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { isExpired } from "react-jwt";
import { useSelector, useDispatch } from 'react-redux';
import { getEstabelecimento, sair } from './store/modules/estabelecimento/actions';

import './'

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

//import "./assets/css/owl.carousel.min.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/themify-icons.css"
import "./assets/css/nice-select.css"
//import "./assets/css/flaticon.css"
import "./assets/css/gijgo.css"
import "./assets/css/animate.min.css"
import "./assets/css/slick.css"
import "./assets/css/slicknav.css"
import "./assets/css/style.css"
import './style.css';


import AuthLayout from './layouts/Auth';
import DefaultLayout from './layouts/Default';
import Inicio from './pages/Inicio';
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import RecuperarSenha from './pages/RecuperarSenha'
import RedefinirSenha from './pages/RedefinirSenha';
import ServicosProdutos from './pages/ServicosProdutos';
import ComboProdutos from './pages/ComboProdutos';
import Perfil from './pages/Perfil';
import Contato from './pages/Contato';
import Planos from './pages/planos';
import Recurso from './pages/Recurso';
import Termo from './pages/Termo';
import PoliticaApp from './pages/Politica_de_Privacidade_App';
import TermoApp from './pages/Termo_de_Uso_App';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import PoliticaCookies from './pages/PoliticaCookies';
import Dashboard from './pages/Dashboard';
import Horarios from './pages/Horarios';
import Convenios from './pages/Convenios';
import Pagamentos from './pages/Pagamentos';
import Imagens from './pages/Imagens';
import OutrosEstabelecimentos from './pages/OutrosEstabelecimentos';
import Link from './pages/Link';
import Beneficio from './pages/Beneficio';



const ARoutes = () => {
  const { accessToken, estabelecimento, form } = useSelector((state) => state.estabelecimento);
  const dispatch = useDispatch(); 
  useEffect(() =>{
    if(!isExpired(accessToken) && accessToken){
      dispatch(getEstabelecimento())
    }else{
      dispatch(sair())
    }
   },[])
  if(isExpired(accessToken) || !accessToken){
    return (
      <Router>
        <AuthLayout>
          <Routes>
            <Route 
              path='*'
              element={<Navigate to="/" />} 
            />
            <Route
              path="/"
              element={<Inicio />}
            />
            <Route
              path="/login"
              exact
              element={<Login />}
            />
            <Route
              path="/cadastro"
              element={<Cadastro />}
            />
            <Route
              path="/recuperar-senha"
              element={<RecuperarSenha />}
            />
            <Route
              path="/redefinir-senha/:id/:token"
              element={<RedefinirSenha />}
            />
            <Route
              path="/contato"
              element={<Contato />}
            />
            <Route
              path="/planos"
              element={<Planos />}
            />
            <Route
              path="/recurso"
              element={<Recurso />}
            />
            <Route
              path="/termo"
              element={<Termo />}
            />
            <Route
              path="/Politica_de_Privacidade_App"
              element={<PoliticaApp />}
            />
             <Route
              path="/Termo_de_Uso_App"
              element={<TermoApp />}
            />
             <Route
              path="/politicaprivacidade"
              element={<PoliticaPrivacidade />}
            />
            <Route
              path="/politicacookies"
              element={<PoliticaCookies />}
            />
            <Route
              path="/redefinir-senha/:id/:token"
              element={<RedefinirSenha />}
            />
             <Route
              path="/Servicos/:id"
              element={<Link />}
            />
          </Routes>
        </AuthLayout>
      </Router>
    )
  }else{
    return (
      <Router>
        <DefaultLayout>
          <Routes>
            <Route 
              path='*'
              element={<Navigate to="/perfil" />} 
              />
            <Route
              path="/servicos-produtos"
              element={<ServicosProdutos />}
            />
            <Route
              path="/combo-produtos"
              element={<ComboProdutos />}
            />
            <Route
              path="/perfil"
              element={<Perfil />}
              />
               <Route 
                path="/termo"
                element={<Termo />}
              />
               <Route 
                path="/Politica_de_Privacidade_App"
                element={<PoliticaApp />}
              />
               <Route 
                path="/Termo_de_Uso_App"
                element={<TermoApp />}
              />
              <Route 
              path="/Dashboard"
              element={<Dashboard />}
              />
              <Route 
                path="/Horarios"
                element={<Horarios />}
              />
              <Route 
                path="/Convenios"
                element={<Convenios />}
              />
              <Route 
                path="/Pagamentos"
                element={<Pagamentos />}
              />
               <Route 
                path="/Beneficio"
                element={<Beneficio />}
              />
              <Route 
                path="/BancoDeImagens"
                element={<Imagens />}
              />
              <Route 
                path="/OutrosEstabelecimentos"
                element={<OutrosEstabelecimentos />}
              />
          </Routes>
        </DefaultLayout>
      </Router>
    )
  }
};

export default ARoutes;