import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { sair } from '../../store/modules/estabelecimento/actions';

import logo from '../../assets/img/logodrclube.png';


const Sidebar = () => {
  const dispatch = useDispatch();

  const handleLogout = (e) =>{
    e.preventDefault();

    dispatch(sair())
  }

  const { estabelecimento } = useSelector(state => state.estabelecimento);

  const location = useLocation();
  return (
    <sidebar className="col-12 col-lg-2 py-0">
      <img src={logo} className="img-fluid px-3 py-4" />
      <ul>
        <li>
          <Link
            to="/perfil"
            className={
              location.pathname === '/perfil' ? 'active' : ''
            }
          >
            <span className="mdi mdi-account" />
            <text>Meu Perfil</text>
          </Link>
        </li>
        <li>
          <Link
            to="/servicos-produtos"
            className={
              location.pathname === '/servicos-produtos' ? 'active' : ''
            }
          >
            <span className="mdi mdi-auto-fix"></span>
            <text>Serviços</text>
          </Link>
          <Link
            to="/combo-produtos"
            className={
              location.pathname === '/combo-produtos' ? 'active' : ''
            }
          >
            <span className="mdi mdi-group"></span>
            <text>Combos</text>
          </Link>
          <Link
            to="/Dashboard"
            className={
              location.pathname === '/Dashboard' ? 'active' : ''
            }
          >
            <span className="mdi mdi-view-dashboard"></span>
            <text>Dashboard</text>
          </Link>
          <Link
            to="/Horarios"
            className={
              location.pathname === '/Horarios' ? 'active' : ''
            }
          >
            <span className="mdi mdi-clock"></span>
            <text>Horários</text>
          </Link>
          <Link
            to="/Convenios"
            className={
              location.pathname === '/Convenios' ? 'active' : ''
            }
          >
            <span className="mdi mdi-flag"></span>
            <text>Convênios</text>
          </Link>
          <Link
            to="/Pagamentos"
            className={
              location.pathname === '/Pagamentos' ? 'active' : ''
            }
          >
            <span className="mdi mdi-credit-card-outline"></span>
            <text>Pagamentos</text>
          </Link>
          { 
            estabelecimento?.beneficio === 'Com benefício' && (
              <Link
                to="/Beneficio"
                className={
                  location.pathname === '/Beneficio' ? 'active' : ''
                }
              >
                <span className="mdi mdi-credit-card-outline"></span>
                <text>Dr. Clube Benefícios</text>
              </Link>
            )
          }
          <Link
            to="/BancoDeImagens"
            className={
              location.pathname === '/BancoDeImagens' ? 'active' : ''
            }
          >
            <span className="mdi mdi-cloud-download"></span>
            <text>Banco de imagens</text>
          </Link>
          <a
           href="https://intercom.help/dr-clube/pt-BR"
         
          target="_blank"
          rel="noopener noreferrer"
          >
         <span className="mdi mdi-comment-question-outline"></span>
         <text>Ajuda</text>
          </a>
          {
            !estabelecimento.filial && (
              <Link
                to="/OutrosEstabelecimentos"
                className={
                  location.pathname === '/OutrosEstabelecimentos' ? 'active' : ''
                }
              >
                <span className="mdi mdi-account-plus"></span>
                <text>Outros estabelecimentos</text>
              </Link>
            )
          }
          <Link
            to="/Termo"
            className={
              location.pathname === '/Termo' ? 'active' : ''
            }
          >
            <span className="mdi mdi-alert-circle-outline"></span>
            <text>Termo de Uso</text>
          </Link>
        </li>
        <li>
          <Link to="/" onClick={handleLogout}>
            <span className="mdi mdi-logout" />
            <text>Sair</text>
          </Link>
        </li>
      </ul>
    </sidebar>
  );
};

export default Sidebar;