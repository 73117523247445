import { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from 'react-redux';

import { allServicos } from '../../store/modules/servico/actions';

import './styles.css';

const Dashboard = () =>{
    const [ano, setAno] = useState(new Date().getFullYear());
    const [mes, setMes] = useState(new Date().getMonth() + 1);

    
    const { estabelecimento } = useSelector((state) => state.estabelecimento);
    const { servicos } = useSelector(state => state.servico);

    console.log(servicos)
    
    const servicosOrdenados = [...servicos].sort((a, b) => b.visualizacoes.length - a.visualizacoes.length);
    const dispatch = useDispatch()

    const totalVisualizacoesServicos = servicos.reduce((acc, obj) => acc + obj.visualizacoes.length, 0)
    
    const visualizacoesDoMes = estabelecimento?.visualizacoes
    ?.filter(item => {
      const dataObj = new Date(item);
      return (dataObj.getFullYear() === Number(ano)) && dataObj.getMonth() === Number(mes) - 1;
    })
    ?.length

    const ligacoesDoMes = estabelecimento?.ligacoes
    ?.filter(item => {
      const dataObj = new Date(item);
      return (dataObj.getFullYear() === Number(ano) || 0 === Number(ano)) && (dataObj.getMonth() === Number((mes) - 1) || 0 === Number(mes));
    })
    ?.length

    const whatsappsDoMes = estabelecimento?.whatsapps
    ?.filter(item => {
      const dataObj = new Date(item);
      return (dataObj.getFullYear() === Number(ano) || 0 === Number(ano)) && (dataObj.getMonth() === Number((mes) - 1) || 0 === Number(mes));
    })
    ?.length
     
    const totalVisualizacoesServicosMes = servicos.reduce((acc, obj) => acc + obj.visualizacoes
    .filter(item => {
      const dataObj = new Date(item);
      return (dataObj.getFullYear() === Number(ano) || 0 === Number(ano)) && (dataObj.getMonth() === Number((mes) - 1) || 0 === Number(mes));
    })
    ?.length, 0)
    
    useEffect(() => {
      dispatch(allServicos());
    }, []);

    return(
      <div className="col p-5 overflow-inherit" style={{minHeight: '100vh'}}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h2 className="mb-4 mt-4 text-muted">Dashboard</h2>
          <div className="d-flex">
            <div>
              <select value={mes} onChange={(e) => setMes(e.target.value)} className="form-control-alternative form-control">
                <option value="0">Todos os meses</option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </select>
            </div>
            <div className="ml-3">
              <select value={ano} onChange={(e) => setAno(e.target.value)} className="form-control-alternative form-control">
                <option value="0">Todos os anos</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                                <div className="text-sm font-weight-bold text-info text-uppercase mb-3">
                                  Visualizações
                                </div>
                            <div className="h4 mb-0 font-weight-bold text-gray-800">{visualizacoesDoMes}</div>
                          </div>
                          <div className="col-auto">
                              <i className="fas fa-sharp fa-eye fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                              <div className="text-sm font-weight-bold text-primary text-uppercase mb-3">
                                    Ligações</div>
                              <div className="h4 mb-0 font-weight-bold text-gray-800">{
                                ligacoesDoMes
                              }</div>
                          </div>
                          <div className="col-auto">
                              <i className="fas fa-sharp fa-phone-volume fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
            <div className="card border-bottom-warning shadow py-2">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col mr-2">
                    <div className="text-sm font-weight-bold text-success text-uppercase mb-3">WhatsApp</div>
                    <div className="h4 mb-0 font-weight-bold text-gray-800">{whatsappsDoMes}</div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                              <div className="text-sm font-weight-bold text-warning text-uppercase mb-3">
                                total de interações</div>
                              <div className="h4 mb-0 font-weight-bold text-gray-800">
                                {
                                  visualizacoesDoMes + ligacoesDoMes + whatsappsDoMes
                                }
                              </div>
                          </div>
                          <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-6 mb-4" style={{ animation: 'slideInLeft 1s forwards' }}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-muted">Mais visualizados</h6>
              </div>
              <div className="card-body" style={{ height: '490px', overflowY: 'auto' }}>
                {servicosOrdenados?.map(e => (
                  <div key={e?._id}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h4 className="h5 font-weight-bold text-gray-900 titulo__topservicos">{e?.titulo}</h4>
                      <span className="text-gray-500">{(((e.visualizacoes.length / totalVisualizacoesServicos) * 100) || 0).toFixed(2)}%</span>
                    </div>
                    <div className="progress mb-4">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${(e.visualizacoes.length / totalVisualizacoesServicos) * 100}%` }}
                        aria-valuenow={(e.visualizacoes.length / totalVisualizacoesServicos) * 100} aria-valuemin="0" aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4" style={{ animation: 'zoomIn 1s forwards' }}>
            <div className="card shadow mb-4" style={{ animation: 'zoomIn 1s forwards' }}>
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-muted">Ligações X WhatsApp</h6>
              </div>
              <div className="card-body" style={{ animation: 'zoomIn 1s forwards' }}>
                <Chart
                  chartType="PieChart"
                  data={[
                    ['Task', 'Contatos'],
                    ['WhatsApp', whatsappsDoMes || ligacoesDoMes || 1],
                    ['Ligações', ligacoesDoMes || whatsappsDoMes || 1],
                  ]}
                  options={{
                    title: '',
                    legend: 'none',
                    pieHole: 0.4,
                    colors: ['#2dce89', '#5e72e4'],
                    backgroundColor: '#f8f9fe',
                    fontName: 'sans-serif',
                    fontSize: 13,
                  }}
                  width={'100%'}
                  height={'400px'}
                  style={{ animation: 'zoomIn 1s forwards' }}
                />
                <div className="mt-4 text-center small">
                  <span className="mr-2 text-sm">
                    <i className="fas fa-circle text-success"></i> WhatsApp
                  </span>
                  <span className="mr-2 text-sm">
                    <i className="fas fa-circle text-primary"></i> Ligações
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Dashboard;