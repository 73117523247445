import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateServico,
  allServicos,
  resetServico,
  removeServico,
} from '../../store/modules/servico/actions';
import util from '../../services/util';

import {
  Icon,
  Button,
  Notification,
  Tag,
  Modal,
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

import Table from '../../components/Table';
import ServicoDrawer from '../../components/ServicoDrawer';
import ServicoTextos from '../../components/ServicoTextos';


const ComboProdutos = () => {
  const dispatch = useDispatch();
  const { servico, servicos, form, components, behavior } = useSelector(
    (state) => state.servico
  );
  const onlyCombos = servicos?.filter(e => e.categoria === "Combo");

  const { estabelecimento } = useSelector(
    state => state.estabelecimento
  )

  const setServico = (key, value) => {
    dispatch(
      updateServico({
        servico: { ...servico, [key]: value },
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      updateServico({
        components: { ...components, [component]: state },
      })
    );
  };

  const edit = (servico) => {
    dispatch(
      updateServico({
        servico,
        behavior: 'update',
      })
    );

    setComponents('drawer', true);
  };

  const remove = () => {
    dispatch(removeServico());
  };

  useEffect(() => {
    dispatch(allServicos());
  }, []);

  return (
    <div className="col p-5 overflow-inherit" style={{minHeight: '100vh'}}>
      <ServicoDrawer combo/>
      <Modal
        show={components.confirmDelete}
        onHide={() => setComponents('confirmDelete', false)}
        size="xs"
      >
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
          {'  '} Tem certeza que deseja excluir? Essa ação será irreversível!
        </Modal.Body>
        <Modal.Footer>
          <Button loading={form.saving} onClick={() => remove()} color="red">
            Sim, tenho certeza!
          </Button>
          <Button
            onClick={() => setComponents('confirmDelete', false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="col-12">
        <div className="alert alert-info d-flex align-items-center border-0" role="alert" style={{ backgroundColor: '#E6F7FF' }}>
        <span
          className="mr-2 d-flex justify-content-center align-items-center rounded-circle"
          style={{ width: '30px', height: '30px', backgroundColor: '#1890FF', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold', aspectRatio: '1' }}
        >
        !
       </span>
       <p>Apenas preços reais praticados pelo seu estabelecimento serão permitidos. Preços fictícios resultarão na exclusão da publicação.</p>
      </div>
          <div className="w-100 d-flex justify-content-between mb-1">
            <h2 className="mb-4 mt-4 text-muted">Combos {onlyCombos?.length}</h2>
            <div>
              <button
                onClick={() => {
                  if (
                    !util.allFields(estabelecimento, [
                      'nomeEmpresarial',
                      'endereco',
                      'telefoneResponsavel',
                      'nomeResponsavel',
                      'cpfResponsavel'
                    ])
                  ) {
                    // DISPARAR O ALERTA
                    Notification.error({
                      placement: 'topStart',
                      title: 'Calma lá!',
                      description: 'Antes de prosseguir, preencha todos os dados no menu perfil!',
                    });
                    return false;
                  }
                  if (
                    !util.allFields(estabelecimento.endereco, [
                      'cidade',
                      'cep',
                      'endereco',
                      'bairro',
                      'numero'
                    ])
                  ) {
                    // DISPARAR O ALERTA
                    Notification.error({
                      placement: 'topStart',
                      title: 'Calma lá!',
                      description: 'Antes de prosseguir, preencha todos os dados no menu perfil!',
                    });
                    return false;
                  }
                  dispatch(
                    updateServico({
                      behavior: 'create',
                    })
                  );
                  dispatch(resetServico());
                  setComponents('drawer', true);
                }}
                className="btn btn-primary btn-lg mb-3 mt-4"
              >
                <span className="mdi mdi-plus"></span> Novo Combo
              </button>
            </div>
          </div>
          <Table 
            rows={onlyCombos}
            loading={form.filtering}
            combo={true}
            config={[
              {
                content: (imagem) => (
                  <img 
                    className="servico-img"
                    src={imagem? `${util.AWS.bucketURL}/${imagem}` : 'https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg'} 
                    style={{
                      width: '190px',
                      height: '190px',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginLeft: '30px'
                    }}
                  />
                ),
                key: 'imagem',
                sortable: true,
                width: 240,
              },
              {
                content: (_id) => <ServicoTextos id={_id}/>,
                key: '_id',
                sortable: true,
                width: 500
              },
              {
                key: 'status',
                width: 65,
                content: (status) => (
                  <Tag
                  color={status === 'A' ? 'green' : 'red'}
                  style={{
                    margin: '1em 0',
                    animation: 'pulse 1s infinite'
                  }}
                >
                  {status === 'A' ? 'Ativo' : 'Inativo'}
                </Tag>
                ),
                sortable: true,
              },
            ]}
            actions={(item) => (
              <>
                <Button
                  
                  size="xs"
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    width: '80px',
                    position: 'absolute', 
                    top: 'calc(50% - 36px)', 
                    right: 0,
                    backgroundColor: '#5DADE2',
                    color:'#FFF',
                    fontWeight: 'bold'
                  }}
                  onClick={() => {
                    edit(item);
                  }}
                >
                  <span class="mdi">Editar</span>
                </Button>
                
                <Button
                 
                  size="xs"
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    width: '80px', 
                    position: 'absolute', 
                    top: 'calc(50% + 12px)', 
                    right: 0,
                    backgroundColor: '#EC7063',
                    color:'#FFF',
                    fontWeight: 'bold'
                  }}
                  onClick={() => {setServico('_id', item?._id); setComponents('confirmDelete', true)}}
                >
                  <span class="mdi">Excluir</span>
                </Button>
              </>
            )}
            onRowClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default ComboProdutos;