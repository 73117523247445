import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import IsVisible from 'react-is-visible';

function Termo() {
  return (
    <>
    <Helmet>
     <title>Termo de uso</title>
     <meta name="description" content="Termos e Condições do Dr. Clube" />
     <link rel='canonical' href='/termo' />
  </Helmet>
    <div className="col p-5">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-13">
                    <div className={`section_title text-left ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                    <br></br>
                    <h2>TERMOS DE USO E CONDIÇÕES DO SITE</h2>
                    <br></br>
                    <br></br>
                       <h4>Termos</h4>
                        &nbsp;
                        <p>Ao acessar ao site Dr .Clube, concorda em cumprir estes termos de uso, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>
                        &nbsp;
                        <h4>Isenção de responsabilidade</h4>
                        &nbsp;
                        <p>1 - Os materiais no site do Dr. Clube são fornecidos 'como estão'. Dr. Clube não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.</p>
                        <p>2 - Além disso, o Dr. Clube não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.</p>
                        &nbsp;
                        <h4>Limitações</h4>
                        &nbsp;
                        <p>Em nenhum caso o Dr. Clube ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais, mesmo que Dr. Clube ou um representante autorizado tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.</p>
                        &nbsp;
                        <h4>Precisão dos materiais</h4>
                        &nbsp;
                        <p>Os materiais exibidos no site do Dr. Clube podem incluir erros técnicos, tipográficos ou fotográficos. O Dr. Clube não garante que qualquer material em seu site seja preciso, completo ou atual. O Dr. Clube pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, O Dr. Clube não se compromete a atualizar os materiais.</p>
                        &nbsp;
                        <h4>Modificações</h4>
                        &nbsp;
                        <p>O Dr. Clube pode revisar estes termos de serviço do site a qualquer momento, serão previamente informados, através do endereço de e-mail cadastrado ou mensagem SMS, sobre alterações nas condições constantes deste termo.</p>
                        &nbsp;
                        <h4>Lei aplicável</h4>
                        &nbsp;
                        <p>Estes termos e condições são regidos e interpretados de acordo com as leis do Dr. Clube e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.</p>
                        &nbsp;




                          <h1>TERMOS DE USO E CONDIÇÕES DO PARCEIRO</h1>
                        <br></br>
                          <p>O presente Termo de Uso estabelece as condições para a parceria entre o estabelecimento de saúde (“Parceiro” ou “Você”) e a empresa fornecedora serviços de Marketplace (“Dr. Clube”), cujo objetivo é fornecer serviços de saúde a Usuários/paciente (“Usuários do Aplicativo Móvel”), conforme descrito neste documento.</p>
                        <br></br>
                          <p>VOCÊ, PARCEIRO, DECLARA SUA INEQUÍVOCA E EXPRESSA ACEITAÇÃO DOS TERMOS E CONDIÇÕES CONSTANTES DESTE TERMO DE USO, SEM RESSALVAS OU RESTRIÇÕES DE QUALQUER ESPÉCIE.</p>
                        <br></br>
                          <p>Este termo poderá sofrer alterações a qualquer momento, a critério do DR. CLUBE. O Parceiro desde já concorda e reconhece que é de sua única e inteira responsabilidade a verificação periódica deste termo. Os PARCEIROS serão previamente informados, através do endereço de e-mail cadastrado ou mensagem SMS, sobre alterações nas condições constantes deste termo.</p>
                        <br></br>
                          <p>Fica desde já estipulado que o PARCEIRO deverá ler certificar-se de haver entendido e aceitar todas as condições estabelecidas no presente Termo, assim como nos demais documentos a ele incorporados por referência, antes de seu cadastro como PARCEIRO do DR. CLUBE</p>
                        <br></br>
                          <h4>Definições</h4>
                        <br></br>
                          <p>1.1. "Parceiro" refere-se ao estabelecimento de saúde que se cadastra na plataforma de Marketplace de consultas e exames e serviços similares. </p>
                          <p>1.2. "Usuário/Paciente" pessoa física que solicita os serviços da plataforma de Marketplace de consultas e exames e serviços similares que busca os serviços oferecidos pelo Parceiro.</p>
                          <p>1.3. Dr. Clube: fornecedora de serviços de Marketplace que mantém a plataforma de publicação dos serviços de consultas, exames e procedimentos similares.</p>
                          <p>1.4. Serviços: serviços de saúde oferecidos pelos parceiros, incluindo agendamento de consultas, exames e procedimentos similares.</p>
                        <br></br>
                        <h4>Objeto</h4>
                        <br></br>
                        <p>1.1. Este Termo de Uso estabelece as condições para a parceria entre o Dr. Clube e o Parceiro, visando a disponibilização dos serviços de consultas, exames e similares por meio da plataforma  Marketplace do Dr. Clube.</p>
                        <p>1.2. O Parceiro declara ter conhecimento prévio de todos os serviços disponibilizados pelo Dr. Clube e concorda em cumprir as normas e regulamentos descritos neste Termo. </p>
                        <p>1.3. O Dr. Clube se compromete a fornecer ao Parceiro o acesso à plataforma de Marketplace para que o Parceiro possa realizar a publicação dos serviços de consultas, exames e procedimentos similares.</p>
                        <br></br>
                        <h4>1. Cadastramento do Parceiro</h4>
                        <br></br>
                        <p>1.1. O cadastramento como parceiro do Dr. Clube está sujeito à aprovação por nossa parte. Reservamo-nos o direito de rejeitar qualquer solicitação de cadastramento caso as informações fornecidas sejam imprecisas, incompletas ou inadequadas., sem a necessidade de justificar nossa decisão.</p>
                        <p>1.2. O Parceiro deverá se cadastrar na plataforma do Dr. Clube, fornecendo todas as informações solicitadas de forma clara, precisa e verdadeira.</p>
                        <p>1.3. O Parceiro devera fornecer informações precisas, completas e atualizadas durante o processo de cadastramento.</p>
                        <p>1.4. Ao se cadastrar como nosso parceiro, você declara que possui a capacidade legal para celebrar este acordo e cumprir todas as obrigações aqui estabelecidas.</p>
                        <p>1.5. A relação entre as partes não configura uma associação, emprego ou qualquer forma de relação contratual diferente daquela estabelecida neste Termo. Você não tem autoridade para vincular o Dr. Clube em qualquer negociação ou acordo com terceiros, salvo expressa autorização por escrito.</p>
                        <p>1.6. O Parceiro é responsável por manter a confidencialidade de suas credenciais de acesso (como nome de usuário e senha) e por todas as atividades realizadas em sua conta. Caso suspeite de qualquer uso não autorizado de sua conta, você deve nos notificar imediatamente.</p>
                        <p>1.7. O Parceiro é responsável por todas as atividades realizadas em sua conta na plataforma. Não compartilhe suas credenciais de acesso com terceiros e não permita que outras pessoas acessem sua conta.</p>
                        <p>1.8. Você concorda em utilizar a plataforma apenas para os fins apropriados e de acordo com as funcionalidades oferecidas. É proibido usar a plataforma para atividades ilegais, fraudulentas, difamatórias, ofensivas, prejudiciais, entre outras.</p>
                        <p>1.9. Você reconhece que podemos atualizar, modificar ou descontinuar a plataforma, bem como alterar ou remover funcionalidades, a nosso critério, sem aviso prévio.</p>
                        <p>1.10. Ao utilizar a plataforma, você concorda com a coleta, armazenamento, processamento e uso de suas informações pessoais, profissionais e empresarias de acordo com nossa Política de Privacidade. É importante que você leia e compreenda nossa política de privacidade antes de prosseguir com o cadastro.</p>
                        <p>1.11. Todos os direitos de propriedade intelectual relacionados à plataforma e aos seus conteúdos são de nossa propriedade ou licenciados para nós. Você concorda em não copiar, modificar, distribuir, transmitir, exibir, vender, licenciar ou explorar qualquer parte da plataforma sem nossa autorização por escrito.</p>
                        <br></br>
                        <h4>2. Obrigações do Parceiro</h4>
                        <br></br>
                        <p>2.1 Ao se cadastrar como parceiro, você reconhece que todos os direitos de propriedade intelectual relacionados aos nossos produtos/serviços, marcas registradas, logotipos, materiais de marketing e outros materiais permanecem exclusivamente conosco.</p>
                        <p>2.2. Como parceiro, você terá acesso a determinados recursos e benefícios, conforme estabelecido em nosso programa de parceria.</p>
                        <p>2.3.  O Parceiro será responsável por todas as informações fornecidas ao Usuário/Paciente em relação aos serviços disponibilizados na plataforma. Essas informações devem ser precisas, atualizadas e completas, de forma a garantir a transparência e a adequada compreensão dos serviços.</p>
                        <p>2.4.  O Parceiro deverá informar ao Usuário/Paciente todas as informações relevantes sobre as condições de utilização dos serviços fornecidos na plataforma.</p>
                        <p>2.5. O Parceiro deverá cumprir todas as leis e regulamentos aplicáveis no exercício de suas atividades, bem como proteger e respeitar os direitos do Usuário/Paciente.</p>
                        <p>2.6. O Parceiro deverá garantir a qualidade e segurança dos serviços prestados ao Usuário/Paciente, atuando de acordo com os mais altos padrões profissionais e éticos.</p>
                        <p>2.7. O Parceiro deverá tratar todas as informações pessoais do Usuário/Paciente com confidencialidade e em conformidade com as leis de proteção de dados aplicáveis.</p>
                        <p>2.8. O Parceiro deverá atender prontamente às solicitações, dúvidas e reclamações apresentadas pelo Usuário/Paciente, buscando sempre a sua satisfação e resolução dos problemas de forma eficiente.</p>
                        <p>2.9. O Parceiro deverá adotar medidas adequadas para proteger a privacidade e a segurança das informações do Usuário/Paciente, incluindo a implementação de sistemas de segurança e o uso de práticas de criptografia, quando aplicável.</p>
                        <p>2.10.  O Parceiro deverá agir de boa-fé em todas as interações com o Usuário/Paciente, buscando estabelecer um relacionamento de confiança e transparência.</p>
                        <p>2.11. O Parceiro deverá cooperar plenamente com o Dr. Clube, fornecendo todas as informações e documentos solicitados, de forma precisa e tempestiva, para a execução adequada dos serviços contratados.</p>
                        <p>2.12.  O Parceiro deverá notificar imediatamente o Dr. Clube sobre qualquer problema, falha ou incidente que possa afetar a prestação dos serviços ou a segurança do Usuário/Paciente.</p>
                        <p>2.13. O Parceiro deverá manter a confidencialidade de qualquer informação privilegiada ou estratégica do Dr. Clube a que tenha acesso durante a parceria, não divulgando ou utilizando tais informações para fins pessoais ou em benefício de terceiros.</p>
                        <p>2.14. O Parceiro deverá cumprir todas as demais obrigações estabelecidas neste Termo de Uso e quaisquer acordos adicionais firmados entre as partes, agindo de acordo com as melhores práticas e padrões do setor.</p>
                        <p>2.15.  O Parceiro deverá manter-se atualizado e em conformidade com todas as exigências legais, regulatórias e éticas aplicáveis à sua atividade, buscando sempre a melhoria contínua de seus serviços e o bem-estar do Usuário/Paciente.</p>
                        <p>2.16. O Parceiro compromete-se a disponibilizar na plataforma do Dr. Clube informações precisas e atualizadas sobre os serviços oferecidos, tais como especialidades, horários disponíveis, endereço do estabelecimento, categorias do estabelecimento, planos de saúde oferecidos, preços das especialidades, imagens dos procedimentos e tipos de agendamento que serão oferecidos aos Usuários/Pacientes. </p>
                        <br></br>
                        <h4>3. Serviços Prestados pelo Parceiro.</h4>
                        <h4>3.1. Geral</h4>
                        <br></br>
                        <p>3.1.1. O Parceiro deverá manter uma comunicação clara e precisa com o Usuário/Paciente, fornecendo todas as informações necessárias para o correto entendimento dos serviços oferecidos.</p>
                        <p>3.1.2. O Parceiro deverá tomar todas as medidas necessárias para evitar qualquer forma de discriminação ou tratamento injusto em relação ao Usuário/Paciente, independentemente de sua raça, etnia, gênero, religião, orientação política, orientação sexual ou qualquer outra característica protegida por lei.</p>
                        <p>3.1.3. O Parceiro é responsável por prestar os serviços aos Usuários/Pacientes com profissionalismo e competência, atendendo a todas as normas técnicas e éticas pertinentes à profissão. </p>
                        <p>3.1.4. O Parceiro compromete-se a manter a confidencialidade de todas as informações dos Usuários/Pacientes, conforme as normas previstas em lei.</p>
                        <br></br>
                        <h4>3.2. Agendamentos Pelo Parceiro</h4>
                        <br></br>
                        <p>3.2.1. O Parceiro é exclusivamente responsável pelo agendamento de consultas, exames, e procedimentos similares, sendo responsável por disponibilizar horários disponíveis, especialidades médicas, profissionais de saúde e quaisquer outros detalhes relevantes. </p>
                        <p>3.2.2. O Parceiro deverá manter a pontualidade no atendimento aos Usuários/Pacientes, respeitando os horários previamente agendados.</p>
                        <p>3.2.3. É importante que o cancelamento ou reagendamento seja feito dentro de um prazo razoável, para minimizar qualquer inconveniente causado ao Usuario/paciente.</p>
                        <p>3.2.4. Durante o processo de cancelamento ou reagendamento, é fundamental manter uma comunicação clara e eficiente com o usuario/paciente. Certifique-se de explicar o motivo do cancelamento ou reagendamento, oferecer alternativas e responder a quaisquer dúvidas ou preocupações que o paciente possa ter. Uma comunicação aberta e respeitosa ajudará a manter um relacionamento positivo com o usuario/paciente.</p>
                        <p>3.2.5. O Parceiro deverá ter uma política clara de cancelamento e reagendamento em sua clínica ou estabelecimento de saúde. Essa política pode incluir informações sobre prazos para cancelamento sem penalidades, taxas de cancelamento, política de reagendamento e outras diretrizes relevantes. Certifique-se de comunicar essa política aos pacientes para evitar mal-entendidos ou conflitos.</p>
                        <p>3.2.6. O Parceiro deve garantir a transparência dos preços e condições de pagamento aos usuarios/pacientes. Todas as informações relacionadas aos preços dos serviços, incluindo quaisquer taxas adicionais ou encargos, devem ser claramente divulgadas antes da prestação dos serviços.</p>
                        <br></br>
                        <h4>3.3. Disponibilidade dos Preços pelo Parceiro.</h4>
                        <br></br>
                        <p>3.3.1. O Parceiro é responsável por garantir que as informações de preço fornecidas sejam precisas e atualizadas.</p>
                        <p>3.3.2. Caso ocorra alguma alteração nas informações de preço, o Parceiro deverá atualizá-las em nossa plataforma.</p>
                        <p>3.3.3. É estritamente proibido utilizar preços fictícios, ou seja, preços que não correspondam à realidade ou que sejam fabricados para enganar ou confundir os usuarios/pacientes. Todos os preços divulgados e apresentados devem ser precisos, reais e representativos dos serviços oferecidos.</p>
                        <br></br>
                        <h4>3.4. Disponibilidade de Convênios pelo Parceiro.</h4>
                        <br></br>
                        <p>3.4.1. O Parceiro só poderá cadastrar convênios firmados com a operadora ou seguradora de saúde responsável.</p>
                        <p>3.4.2. O Dr. Clube declara expressamente a proibição da prática de convênio por reembolso. Isso significa que é estritamente proibido oferecer serviços aos usuarios/pacientes por meio de convênios que envolvam o pagamento posterior de reembolsos por parte de seguradoras de saúde.</p>
                        <br></br>
                        <h4>3.5. Disponibilidade e Uso de Imagens pelo Parceiro.</h4>
                        <br></br>
                        <h4>Imagens Própria do parceiro:</h4>
                        <br></br>
                        <p>3.5.1. O Parceiro deverá garantir que o uso das imagens esteja em conformidade com as leis aplicáveis, respeitando os direitos autorais, direitos de privacidade e quaisquer outros direitos de terceiros relacionados às imagens.</p>
                        <p>3.5.2. O Parceiro utilizará as imagens próprias de forma adequada, ética e legal. O Parceiro não deve utilizar as imagens de maneira difamatória, ofensiva, obscena, ilegal ou prejudicial à reputação do Dr. Clube ou de terceiros. O uso das imagens deve ser feito de acordo com os padrões profissionais e as leis e regulamentos aplicáveis.</p>
                        <p>3.5.3. Você assume total responsabilidade por qualquer violação de direitos autorais ou danos causados por uso indevido das imagens em nossa plataforma.</p>
                        <br></br>
                        <h4>Imagens do Banco de Fotos da Plataforma.</h4>
                        <br></br>
                        <p>3.5.4. Usamos a API do banco de fotos do PEXELS para você usar gratuitamente nas publicações dos serviços.</p>
                        <p>3.5.5. Você está autorizado a usar as imagens disponibilizadas em nossa plataforma de acordo com as finalidades estabelecidas em nossos termos de uso e finalidade específica da plataforma.</p>
                        <br></br>
                        <h6>3.5.6. Você está autorizado a realizar as seguintes ações:</h6>
                        <br></br>
                        <p>1. A atribuição não é necessária.</p>
                        <p>2. Você pode modificar as fotos e vídeos. Seja criativo e edite-os como quiser.</p>
                        <p>3. Você poderá usar as fotos e vídeos fora da plataforma Dr. Clube mas respeitando os termos de uso da PEXELS. <a href="https://www.pexels.com/terms-of-service/">Leia os termos da Pexels</a></p>
                        <br></br>
                        <h6>3.5.7. Você não está autorizado a realizar as seguintes ações em relação às imagens disponibilizadas em nossa plataforma, sem a devida autorização dos proprietários dos direitos autorais:</h6>
                        <br></br>
                        <p>1. Vender cópias inalteradas, sublicenciar ou distribuir as imagens a terceiros.</p>
                        <p>2. Não implique endosso de seu produto por pessoas ou marcas nas imagens.</p>
                        <p>3. Utilizar as imagens de forma difamatória, obscena, ilegal ou de qualquer maneira que possa violar direitos de terceiros.</p>
                        <br></br>
                        <h4>4. Obrigações do Dr. Clube</h4>
                        <br></br>
                        <p>O Dr. Clube tem a obrigação de fornecer os serviços ou produtos acordados de acordo com os termos e condições estabelecidos em contratos, acordos ou termos de serviço.</p>
                        <p>4.1. O Dr. Clube tem a obrigação de cumprir todas as leis, regulamentos e normas aplicáveis ao seu setor de atuação. Isso inclui leis trabalhistas, ambientais, fiscais, de privacidade, de proteção ao consumidor e outras.</p>
                        <p>4.2. O Dr. Clube deve garantir que os produtos ou serviços oferecidos atendam aos padrões de qualidade acordados, sejam seguros, eficientes e estejam em conformidade com as leis e regulamentos aplicáveis.</p>
                        <p>4.3. O Dr. Clube deverá garantir a confidencialidade e segurança dos dados do Usuário/Paciente, conforme sua política de privacidade. </p>
                        <p>4.4. O Dr. Clube deverá fornecer ao Parceiro todas as informações necessárias para o bom uso da plataforma de Marketplace.</p>
                        <p>4.5. O Dr. Clube deve fornecer um atendimento ao cliente eficiente, cortês e profissional. Isso inclui responder a consultas, reclamações e solicitações de suporte de forma adequada e dentro de um prazo razoável.</p>
                        <p>4.6. O Dr. Clube deve proteger os dados pessoais e informações confidenciais de seus clientes de acordo com as leis de proteção de dados e privacidade aplicáveis. Isso inclui a implementação de medidas de segurança adequadas e a obtenção de consentimento quando necessário.</p>
                        <p>4.7. O Dr. Clube deve agir de acordo com altos padrões éticos e de integridade em todas as suas atividades comerciais. Isso inclui evitar práticas antiéticas, como corrupção, suborno, concorrência desleal e discriminação.</p>
                        <p>4.8. O Dr. Clube é responsável por proporcionar um ambiente de trabalho seguro e saudável para seus funcionários, cumprindo as normas de segurança ocupacional e prevenção de acidentes.</p>
                        <br></br>
                        <h4>5. Termo de Prazo e Cancelamento do Serviço. </h4>
                        <br></br>
                        <p>Este Termo estabelece as disposições referentes ao prazo de vigência e ao cancelamento do serviço fornecido pelo Dr. Clube. Ao utilizar o serviço, o PARCEIRO concorda em cumprir as seguintes condições.</p>
                        <br></br>
                        <h4>5.1. Prazo de Vigência:</h4>
                        <br></br>
                        <p>O serviço terá início na data de cadastro na plataforma e terá duração determinada de acordo com o uso dos serviços.</p>
                        <br></br>
                        <h4>5.2. Cancelamento pelo Parceiro</h4>
                        <br></br>
                        <p>5.2.1. Para cancelar sua conta na plataforma, você deve seguir o procedimento de cancelamento especificado na área de configurações da sua conta com as seguintes opções:</p>
                        <p>1. Excluir a conta.</p>
                        <p>2. Excluir todos os serviços se quiser permanecer com a conta.</p>
                        <p>5.2.2. Ao solicitar o cancelamento da conta, você reconhece que essa ação é irreversível e resultará na exclusão permanente de todos os dados associados à sua conta. Isso inclui informações de perfil, histórico de atividades e outros dados relacionados.</p>
                        <p>5.2.3. Após o cancelamento da conta, não utilizaremos mais suas informações pessoais, exceto quando necessário para cumprir obrigações legais ou para a proteção de direitos, segurança e integridade da plataforma.</p>
                        <p>5.2.4. Se desejar utilizar a plataforma novamente após o cancelamento da conta, você deverá realizar um novo cadastro e concordar com os termos e condições atualizados.</p>
                        <p>OBS: O Dr. Clube só cobrará se o PARECEIRO possuir serviços publicados.</p>
                        <br></br>
                        <h4>5.3. Cancelamento pelo Dr. Clube</h4>
                        <br></br>
                        <p>O Dr. Clube reserva-se o direito de cancelar o serviço a qualquer momento, mediante aviso prévio ao parceiro, nos casos em que ocorra violação do termos de uso, inadimplência, uso indevido do serviço ou em situações previstas na legislação aplicável.</p>
                        <br></br>
                        <h4>6. Faturamento e Cobrança da Conta</h4>
                        <br></br>
                        <p>Este Termo de Uso ("Termo") estabelece os termos e condições relacionados ao faturamento da conta de serviço fornecido pelo Dr. Clube. Ao utilizar nossos serviços e concordar com este Termo, você concorda em cumprir todas as cláusulas aqui descritas.</p>
                        <br></br>
                        <h4>6.1. Faturamento</h4>
                        <br></br>
                        <p>6.1.1. Você concorda em fornecer informações de pagamento precisas, completas e atualizadas. Ao fornecer os detalhes do cartão de crédito, débito ou qualquer outra forma de pagamento, você declara ter autoridade para usar esse método de pagamento.</p>
                        <p>6.1.2.. Ao se cadastrar no Dr. Clube, você concorda em pagar pelos serviços utilizados de acordo com as tarifas e os termos de pagamento estabelecidos.</p>
                        <p>6.1.3. O faturamento será realizado de acordo com o plano escolhido ou os serviços utilizados, conforme especificado em nosso site ou em outros documentos relacionados.</p>
                        <p>6.1.4. Os pagamentos devem ser feitos dentro do prazo estabelecido em nossa política de faturamento, utilizando os métodos de pagamento disponíveis e aceitos na plataforma.</p>
                        <p>6.1.5. Caso ocorra qualquer atraso no pagamento, nos reservamos o direito de suspender ou encerrar os serviços prestados até que o pagamento seja regularizado.</p>
                        <br></br>
                        <h4>6.2. Cobrança</h4>
                        <br></br>
                        <p>6.2.1. Os valores cobrados serão baseados nos registros de uso de nossos serviços, que são considerados precisos e válidos, a menos que haja uma contestação formal por parte do Parceiro.</p>
                        <p>6.2.2. Quaisquer contestações devem ser feitas ao nosso departamento de atendimento ao parceiro, dentro do prazo especificado em nossa política de contestação de cobrança.</p>
                        <p>6.2.3 Se uma contestação for considerada válida após análise, qualquer valor em disputa será reembolsado ou ajustado na próxima fatura, conforme o caso.</p>
                        <br></br>
                        <h4>6.3. Alterações nos Preços e Faturamento</h4>
                        <br></br>
                        <p>6.3.1. Reservamo-nos o direito de modificar as tarifas de nossos serviços a qualquer momento, mediante notificação prévia adequada.</p>
                        <p>6.3.2. Qualquer alteração nos preços ou nas condições de faturamento será comunicada a você por meio de aviso em nosso site, envio de e-mail ou qualquer outro meio de comunicação disponível.</p>
                        <p>6.3.3. Após o recebimento da notificação, você terá o direito de aceitar as alterações ou cancelar os serviços, de acordo com os procedimentos estabelecidos em nosso site ou em outros documentos relacionados.</p>
                        <br></br>
                        <h4>6.4. Reembolso</h4>
                        <br></br>
                        <p>6.4.1. Em caso de cancelamento pelo PARCEIRO dentro do prazo de reflexão ou em situações específicas previstas em lei, o Dr. Clube poderá oferecer reembolso proporcional ao valor pago pelo serviço, deduzindo eventuais taxas de cancelamento ou despesas administrativas.</p>
                        <br></br>
                        <h4>6.5. Privacidade e Proteção de Dados de Faturamento</h4>
                        <br></br>
                        <p>6.5.1. As informações fornecidas para fins de faturamento serão tratadas de acordo com nossa Política de Privacidade e Proteção de Dados.</p>
                        <p>6.5.2. Não compartilharemos suas informações de cobrança com terceiros, a menos que seja necessário para a execução dos serviços contratados ou exigido por lei.</p>
                        <br></br>
                        <h4>7. Limitação de Responsabilidade </h4>
                        <br></br>
                        <p>Este Termo de Limitação de Responsabilidade ("Termo") estabelece os termos e condições em relação à limitação da responsabilidade do Dr. Clube. Ao utilizar nossos serviços e concordar com este Termo, você concorda em cumprir todas as cláusulas aqui descritas.</p>
                        <p>7.1. O Dr. Clube é responsável pela plataforma Marketplace de serviços de consultas, exames e procedimentos similares, não tendo qualquer responsabilidade sobre o atendimento prestado pelo Parceiro. </p>
                        <p>7.2. O Dr. Clube não se responsabiliza por quaisquer danos, prejuízos ou perdas sofridas pelo Parceiro ou pelos Usuários/Paciente decorrentes da utilização da plataforma Dr. Clube. </p>
                        <p>7.3. O Parceiro se responsabiliza por todas as informações fornecidas ao Usuário/Paciente em relação aos serviços disponibilizados, bem como por todas as informações fornecidas durante o processo de parceria. </p>
                        <p>7.4. Em nenhuma hipótese o Dr. Clube será responsável por quaisquer danos indiretos, lucros cessantes ou perda de dados decorrentes da utilização da plataforma.</p>
                        <p>7.5. O Parceiro concorda em indenizar, defender e isentar o D. Clube, seus diretores, funcionários, agentes, afiliados e fornecedores de quaisquer reclamações, ações, demandas, responsabilidades, custos e despesas decorrentes ou relacionados ao seu uso de nossos serviços, incluindo qualquer violação deste Termo.</p>
                        <p>7.6. A plataforma é fornecida "no estado em que se encontra" e "conforme disponível", sem garantias de qualquer tipo. Não nos responsabilizamos por quaisquer danos ou prejuízos decorrentes do uso da plataforma.</p>
                        <p>7.7. Nós nos esforçamos para garantir a precisão, segurança e disponibilidade da plataforma, mas não garantimos que a plataforma estará livre de erros, interrupções, vírus ou outros componentes prejudiciais.</p>
                        <br></br>
                        <h4>8. Disposições Gerais</h4>
                        <br></br>
                        <p>8.1. Este Termo constitui o acordo completo entre as partes, prevalecendo sobre qualquer outro entendimento ou acordo verbal ou escrito. </p>
                        <p>8.2. Este Termo poderá ser alterado pelo Dr. Clube a qualquer momento, mediante aviso prévio ao Parceiro. </p>
                        <p>8.3. Caso qualquer disposição deste Termo seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito. </p>
                        <p>8.4. Este Termo será regido e interpretado de acordo com as leis brasileiras, e as partes elegem o foro da Comarca de São Paulo para dirimir quaisquer questões decorrentes deste Termo.</p>



                    </div>
                </div>
            </div>
            </>
            )}
            </IsVisible>
        </div>
    </div>
    </>
  )
}

export default Termo;