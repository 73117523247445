import React from 'react';
import { Link } from 'react-router-dom';

import IsVisible from 'react-is-visible';

function Termo_de_Uso_App() {
  return (
    <div className="col p-5">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-13">
                    <div className={`section_title text-left ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                    <br></br>
                    <h1>TERMOS DE USO E CONDIÇÕES DO APP</h1>
                    <br></br>
                    <br></br>
                    <p>Bem-vindo ao nosso aplicativo de serviços Dr. Clube. Por favor, leia atentamente estes Termos de Uso antes de utilizar nosso aplicativo.</p>
                    <br></br>
                    <br></br>
                       <h4>1. Aceitação dos Termos</h4>
                       <br></br>
                       <p>1.1. Ao utilizar o Aplicativo, você declara ter lido, compreendido e concordado com os termos e condições estabelecidos neste Termo de Uso Completo. É importante que você revise este documento regularmente, pois ele pode ser atualizado de tempos em tempos. Se tiver alguma dúvida sobre o uso do Aplicativo ou sobre este Termo, entre em contato com o Dr. Clube para obter esclarecimentos adicionais. Se você não concorda com estes termos, por favor, não utilize nosso aplicativo.</p>
                       <br></br>
                       <h4>2. Objetivo do Aplicativo</h4>
                       <br></br>
                       <p>2.1. O Aplicativo tem como objetivo permitir a reserva e acesso a consultas médicas, exames e serviços relacionados, oferecidos por profissionais e parceiros cadastrados na plataforma ("Prestadores de Serviço").</p>
                       <p>2.2. O Aplicativo é um intermediário entre você e os Prestadores de Serviço. Nós fornecemos uma plataforma para facilitar o contato e a reserva de consultas e exames, mas não nos responsabilizamos pela prestação dos serviços prestados.</p>
                       <p>2.3.  O Aplicativo é fornecido apenas como uma plataforma intermediária e o Dr. Clube não assume qualquer responsabilidade pelos serviços prestados pelos provedores de serviços de saúde cadastrados no Aplicativo. A relação contratual para a prestação de serviços ocorre exclusivamente entre o usuário e o provedor de serviços de saúde.</p>
                       <br></br>
                       <h4>3. Cadastro no Aplicativo</h4>
                       <br></br>
                       <p>3.1. No momento não utilizamos cadastro para ter acesso ao aplicativo.</p>
                       <br></br>
                       <h4>4. Sobre os Prestadores de Serviço</h4>
                       <br></br>
                       <p>4.1. Os Prestadores de Serviço são responsáveis pela prestação dos serviços de saúde e similares, incluindo consultas médicas, exames e outros serviços relacionados. Eles são profissionais e estabelimentos independentes, não sendo empregados ou representantes do Dr. Clube.</p>
                       <p>4.2. O Aplicativo fornece informações sobre os Prestadores de Serviço, como suas especialidades, disponibilidade, localização e outros. No entanto, não garantimos a precisão ou qualidade dos serviços prestados pelos Prestadores de Serviço.</p>
                       <br></br>
                       <h4>5. Reserva e Agendamento de Serviços</h4>
                       <br></br>
                       <p>5.1. Você pode consultar e agendar e reservar serviços de saúde e similares através do nosso aplicativo. O agendamento está sujeito à disponibilidade de horários e profissionais. o Estabelecimento parceiro poderá entrar em contato para confirmar o seu agendamento e fornecer informações e coletar informações adicionais.</p>
                       <br></br>
                       <h4>6. Pagamento pelos Serviços</h4>
                       <br></br>
                       <p>6.1. Todos os pagamentos relacionados aos serviços oferecidos pelos estabelecimentos e Prestadores de Serviço devem ser efetuados exclusivamente no local de atendimento físico designado. Não aceitamos pagamentos online, por telefone ou qualquer outra forma de pagamento remoto.</p>
                       <p>6.2. Os meios de pagamento aceitos neste estabelecimento serão divulgados claramente no local de atendimento. Essas formas de pagamento podem incluir dinheiro, cartões de crédito, cartões de débito ou qualquer outro método de pagamento físico disponibilizado pelo estabelecimento.</p>
                       <p>6.3. O Dr. Clube não se responsabiliza por quaisquer pagamentos efetuados fora do local de atendimento, incluindo pagamentos online ou por telefone. Recomendamos veementemente que os usuários sigam essa política de pagamentos e evitem qualquer transação que não esteja em conformidade com essas diretrizes.</p>
                       <p>6.4. Todas as comunicações oficiais relacionadas a pagamentos, faturas ou cobranças serão realizadas no local de atendimento. O estabelecimento se reserva o direito de recusar qualquer pagamento que não esteja de acordo com essas diretrizes.</p>
                       <p>6.5. O não cumprimento desta política de pagamentos pode resultar na recusa de serviços ou na aplicação de medidas adicionais a critério exclusivo do estabelecimento. Além disso, qualquer pagamento realizado fora do local de atendimento será considerado inválido, e o usuário será responsável por qualquer consequência decorrente dessa transação.</p>
                       <br></br>
                       <h4>7. Responsabilidades do Usuário</h4>
                       <br></br>
                       <p>Ao utilizar o Aplicativo, você concorda em:</p>
                       <p>7.1. Fornecer informações precisas e atualizadas durante o processo de agendamento;</p>
                       <p>7.2. Respeitar as políticas e diretrizes estabelecidas pelos Prestadores de Serviço;</p>
                       <p>7.3. Comparecer pontualmente às consultas ou exames agendados. Em caso de impossibilidade de comparecimento, você deve cancelar ou reagendar a consulta dentro do prazo estabelecido pelo Prestador de Serviço;</p>
                       <p>7.4. Pagar pelos serviços de acordo com as políticas de cobrança estabelecidas pelos Prestadores de Serviço.</p>
                       <br></br>
                       <br></br>
                    </div>
                </div>
            </div>
            </>
            )}
            </IsVisible>
        </div>
    </div>
  )
}

export default Termo_de_Uso_App;