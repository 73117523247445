import { useLocation, Navigate, useParams } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from '../../components/Header';

import Helmet from 'react-helmet'
import Footer from '../../components/Footer';

const AuthLayout = ({children}) =>{
  const location = useLocation();
  const { id, token }  = useParams();
  return(
    <div>
      <Helmet title={`Dr. Clube${
          location.pathname === '/' ? '' :
          location.pathname === '/login'? ' - Fazer Login':
          location.pathname === '/cadastro'? ' - Criar conta':
          location.pathname === '/recuperar-senha'? ' - Recuperar senha':
          location.pathname === '/contato'? ' - Contato':
          location.pathname === '/planos'? ' - Preço':
          location.pathname === '/recurso'? ' - Recursos':
          location.pathname === '/termo'? ' - Termo de uso':
          location.pathname === '/politicaprivacidade'? ' - Política de privacidade':
          location.pathname === '/politicacookies'? ' - Política de cookies':
          ''
        }`}
      />
      <div className="row">
        <Header signed={false}/>
          <div className="main-content w-100">
            {
              location.pathname != '/' && (
                <div className="bradcam_area bradcam_bg_1">
                  <div className="container">
                      <div className="row">
                          <div className="col-xl-12">
                              <div className="bradcam_text text-center">
                                  <h3>
                                  { 
                                    location.pathname === '/login'? 'Fazer Login':
                                    location.pathname === '/cadastro'? 'Criar conta':
                                    location.pathname === '/recuperar-senha'? 'Recuperar senha':
                                    location.pathname === '/contato'? 'Contato':
                                    location.pathname === '/planos'? 'Preço Acessível':
                                    location.pathname === '/recurso'? 'Recursos':
                                    location.pathname === '/termo'? 'Termo de uso':
                                    location.pathname === '/politicaprivacidade'? 'Política de privacidade':
                                    location.pathname === '/politicacookies'? 'Política de cookies':
                                    ''
                                  }
                                  </h3>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              )
            }
          </div>
      </div>
      {children}
      {
        ![
          '/login',
          '/cadastro',
          '/recuperar-senha',
        ].includes(location.pathname)  &&
        !location.pathname.includes('redefinir-senha') &&
        <Footer/>
      } 
    </div>
  )
}
export default AuthLayout;