import { useState, useEffect } from 'react';

import { Table } from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

const TableComponent = ({ rows, onRowClick, config, actions, loading, combo}) => {
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const sortColumns = (sortColumn, sortType) => {
    const newData = data.sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];

      if (typeof x === 'string') {
        x = x.charCodeAt();
      }
      if (typeof y === 'string') {
        y = y.charCodeAt();
      }
      if (sortType === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
  });
    setData(newData);
  };

  useEffect(() => {
    setData(rows);
  }, [rows]);
  
  return (
    <>
      <Table
        onSortColumn={(sortColumn, sortType) => {
          sortColumns(sortColumn, sortType);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }}
        sortColumn={sortColumn}
        sortType={sortType}
        height={data.length * 220 + 130}
        data={data}
        loading={loading}
        onRowClick={(c) => onRowClick(c)}
        locale={{ emptyMessage: combo ?"Você não tem combos criados" :  "Você não tem serviços criados."}}
      >
        {config.map((c) => (
          <Column fixed={c.fixed} width={c.width}>
            <HeaderCell />
            {!c.content ? (
              <Cell dataKey={c.key} />
            ) : (
              <Cell dataKey={c.key}>{(item) => c.content(item[c.key])}</Cell>
            )}
          </Column>
        ))}

        <Column width={250} fixed="right">
          <HeaderCell />

          <Cell>{(item) => actions(item)}</Cell>
        </Column>
      </Table>

      {/*<Pagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 20,
            label: 20,
          },
        ]}
        activePage={1}
        displayLength={2}
        total={data.length}
        onChangePage={() => {}}
        onChangeLength={() => {}}
      />*/}
    </>
  );
};

export default TableComponent;
