import React from 'react';

import { Modal, Icon, Button } from 'rsuite';

function NoResponsive({show, setShow}) {
  return (
    <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xs"
      >
      <Modal.Body>
          <Icon
          icon="remind"
          style={{
              color: '#ffb300',
              fontSize: 24,
          }}
          />
          {'  '} Nosso site ainda não da suporte para dispositivos móveis, por favor use um computador.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)} color="blue">
          ok
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NoResponsive;