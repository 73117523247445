import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom';
import { isExpired } from 'react-jwt';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import api from '../../services/api';
import * as yup from "yup";

import { notification } from '../../services/rsuite';
import { updateEstabelecimento } from '../../store/modules/estabelecimento/actions';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';


const RedefinirSenha = () => {
  const { id, token }  = useParams();
  const history = useNavigate();
  
  const { form } = useSelector(state => state.estabelecimento);
  const dispatch = useDispatch();
  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      }
    ));
  };

  const schema = yup.object().shape({
    senha1: yup.string('insira uma senha válida!').required('insira uma senha!').min(8, 'insira uma senha com 8 dígitos ou mais!'),
  })
  const onSubmit = async (e) =>{
    setEstabelecimento('saving', true)
    if(form?.senha1 !== form?.senha2){
      notification('error', { placement: 'topStart',title: 'Atenção!', description: "As senhas precisam ser iguais!"});
      setEstabelecimento('saving', false)
      return false;
    }
    const { data: res } = await api.post(`/auth/redefinirSenha/${id}`, { senha: form?.senha1, token });

    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops!', description: res.message});
      setEstabelecimento('saving', false)
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto!', description: res.message});
    setEstabelecimento('saving', false)
    return false;
  }
  const { register, handleSubmit, formState: { errors }  } = useForm({
    resolver: yupResolver(schema)
  })
  return (
    <Container className="mt--9 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7 mx-auto h-100 mt-5">
          <Card className="bg-secondary shadow border-0 mt-5">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={form.saving? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      placeholder="Nova senha"
                      type="password"
                      autoComplete="new-password"
                      className="form-control"
                      ref={register}
                      {...register("senha1", {
                        onChange:(e) => setEstabelecimento('senha1', e?.target.value)
                      })}
                    />
                  </InputGroup>
                  {
                    errors.senha1?.type &&
                    <small className="validacao">{errors?.senha1?.message}</small>
                  }
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      placeholder="Confirmar senha"
                      type="password"
                      autoComplete="new-password"
                      className="form-control"
                      onChange={(e) => setEstabelecimento('senha2', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  {
                    form?.saving? (
                        <Button className="m3-4" color="primary">
                          <Spinner size="sm"/>
                        </Button>
                      ): (
                        <Button className="m3-4" color="primary" type="submit">
                          Redefinir
                        </Button>
                      )
                  }
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link
                className="text-light"
                to="/login"
              >
                <small>Fazer login</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default RedefinirSenha;