const types = {
  GET_ESTABELECIMENTO: '@estabelecimento/GET',
  UPDATE_ESTABELECIMENTO: '@estabelecimento/UPDATE',
  LOGIN_ESTABELECIMENTO: '@estabelecimento/LOGIN',
  CADASTRAR_ESTABELECIMENTO: '@estabelecimento/CADASTRAR',
  SAIR_ESTABELECIMENTO: '@estabelecimento/SAIR',
  EDITAR_ESTABELECIMENTO: '@estabelecimento/EDITAR',
  DELETAR_ESTABELECIMENTO: '@estabelecimento/DELETAR',
  REMOVERARQUIVO_ESTABELECIMENTO: '@estabelecimento/REMOVERARQUIVO',
};

export default types;