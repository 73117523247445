import React, { useEffect } from 'react'
import IlstratorPhone6 from "../../assets/img/ilstrator/phone6.png"
import IlstratorPhone4 from "../../assets/img/ilstrator/phone4.png"
import IlstratorPhone5 from "../../assets/img/ilstrator/phone5.png"
import IlstratorPhone7 from "../../assets/img/ilstrator/phone7.png"
import IlstratorTela1 from "../../assets/img/ilstrator/tela1.png"
import IlstratorNote from "../../assets/img/ilstrator/note3.png"
import Icon1 from "../../assets/img/icon/1.svg"
import Icon2 from "../../assets/img/icon/2.svg"
import Icon3 from "../../assets/img/icon/3.svg"
import { Link } from 'react-router-dom';
import 'animate.css';
import IsVisible from 'react-is-visible';
import AdoptScript from '../../services/AdoptScript';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';


function Recurso() {
  return (
    <>
    <Helmet>
     <title>Recursos</title>
     <meta name="description" content="Confira as funções do nosso Marketplace, que conectará seu estabelecimento de saúde a milhares de clientes." />
     <link rel='canonical' href='/recurso' />
  </Helmet>
  <div className="service_area">
            <div className="container">
                <IsVisible>
                        {(IsVisible) => (
                        <>
                             <div className="row">
                <div className="col-xl-12">
                    <div className={`section_title text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                        <h3>Seu negócio mais perto dos clientes!</h3>
                    </div>
                </div>
            </div>                        
                     </>
                        )}
                </IsVisible>
                </div>
            </div>
           
  <div className="features_area ">
        <div className="container">
        <IsVisible>
                {(IsVisible) => (
                <>
                    <div className="features_main_wrap">
                        <div className="row align-items-center">
                                <div className="col-xl-5 col-lg-5 col-md-6">
                                    <div className={`features_info2 ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                                        <h3>Visualização
                                          do seu negócio</h3>
                                        <p>Quando se trata de fornecer informações relevantes sobre o seu negócio, é essencial oferecer uma experiência personalizada e conveniente para seus clientes. Com nossas soluções avançadas, aprimoramos a maneira como os clientes interagem com suas informações, permitindo que eles encontrem exatamente o que precisam, seja por geolocalização, campo de busca ou filtragem.</p>                                    
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-5 offset-xl-1 offset-lg-1 col-md-6 ">
                                    <div className={`about_image  ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                                        <img src={IlstratorPhone5} alt="..." style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </>
                )}
            </IsVisible>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <IsVisible>
                {(IsVisible) => (
                <>
                <div>
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-5 offset-xl-1 offset-lg-1 col-md-6">
                            <div className={`about_image  ${IsVisible && 'animate__animated animate__fadeInLeft'}`}>
                                <img src={IlstratorPhone4} alt="..." style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="features_info">
                                <h3 className={IsVisible && 'animate__animated animate__fadeInUp'}>Visualização
                                          dos serviços</h3>
                                <p className={IsVisible && 'animate__animated animate__fadeInUp'}>Crie e personalize uma ampla variedade de serviços e combos, incluindo consultas, exames, estética, terapias, beleza, serviços para pets e muito mais.</p>                             
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            </IsVisible>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <IsVisible>
                {(IsVisible) => (
                <>
                    <div className="features_main_wrap">
                        <div className="row align-items-center">
                                <div className="col-xl-5 col-lg-5 col-md-6">
                                    <div className={`features_info2 ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                                        <h3>Disponibilize opções de agendamentos</h3>
                                        <p>Agora o cliente pode agendar de forma rápida e fácil pelo WhatsApp e fazer ligações diretas através do aplicativo! </p>                                    
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-5 offset-xl-1 offset-lg-1 col-md-6 ">
                                    <div className={`about_image  ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                                        <img src={IlstratorPhone6} alt="..." style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </>
                )}
            </IsVisible>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <IsVisible>
                {(IsVisible) => (
                <>
                <div>
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-5 offset-xl-1 offset-lg-1 col-md-6">
                            <div className={`about_image  ${IsVisible && 'animate__animated animate__fadeInLeft'}`}>
                                <img src={IlstratorPhone7} alt="..." style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="features_info">
                                <h3 className={IsVisible && 'animate__animated animate__fadeInUp'}>Visualização
                                          dos Convênios</h3>
                                <p className={IsVisible && 'animate__animated animate__fadeInUp'}>Disponibilize os convênios aceitos pelo seu estabelecimento e expanda o alcance do seu negócio.</p>                             
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            </IsVisible>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
         
        </div>
    </div>
    <div className="productivity_area">
    <IsVisible>
            {(IsVisible) => (
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-md-12 col-lg-6">
                        <h3 className={`${IsVisible && 'animate__animated animate__fadeInDown'}`} style={{ color: '#FFF', fontWeight: 'normal' }}>Seja um parceiro e comece a receber clientes hoje mesmo!</h3>
                        </div>
                        <div className="col-xl-5 col-md-12 col-lg-6">
                            <div className={`app_download ${IsVisible && 'animate__animated animate__fadeInDown'}`}>
                                <Link to="/cadastro">
                                    <Link className="boxed-btn4" to="/cadastro">Seja um parceiro</Link>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                </div>
            )} 
        </IsVisible>
        
    </div>
    </>
  )
}

export default Recurso;