import React, { useEffect } from 'react';

const AdoptScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//tag.goadopt.io/injector.js?website_code=213cedd1-f406-45ca-aad0-f344125c52e6';
    script.className = 'adopt-injector';
    document.body.appendChild(script);

    const meta = document.createElement('meta');
    meta.name = 'adopt-website-id';
    meta.content = '213cedd1-f406-45ca-aad0-f344125c52e6';
    document.head.appendChild(meta);

    return () => {
      // Limpar o script e a tag <meta> quando o componente for desmontado
      document.body.removeChild(script);
      document.head.removeChild(meta);
    };
  }, []);

  return null;
};

export default AdoptScript;