import React from 'react';

import IsVisible from 'react-is-visible';

function Politica_de_Privacidade_App() {
  return (
    <div className="col p-5">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-13">
                    <div>
                    <br></br>
                    <h1>POLÍTICA DE PRIVACIDADE</h1>
                    <br></br>
                    <br></br>
                    <p>Bem-vindo ao nosso aplicativo de serviços Dr. Clube. Por favor, leia atentamente esta política de Privacidade antes de utilizar nosso aplicativo.</p>
                    <br></br>
                    <br></br>
                       <h4>Privacidade e Proteção de Dados pelo Dr. Clube</h4>
                       <br></br>
                       <p>Este Termo de Privacidade descreve como são coletadas, usadas, armazenadas e compartilhadas as informações pessoais dos usuários ao utilizar o nosso aplicativo móvel. Ao utilizar o aplicativo, você concorda com as práticas descritas neste termo.</p>
                       <br></br>
                       <p>1. Informações Coletadas:</p>
                       <p>Informações fornecidas pelo usuário:</p>
                       <p>1.1. Podemos solicitar informações pessoais, como nome, endereço de e-mail, número de telefone, para melhorar a experiência do usuário e fornecer suporte adequado.</p>
                       <p>Informações de uso:</p>
                       <p>1.2. Podemos coletar informações sobre o uso do aplicativo, incluindo interações com os recursos, dados de localização aproximada e outras informações de diagnóstico para melhorar a qualidade e desempenho do aplicativo.</p>
                       <p>2. Uso das Informações:</p>
                       <p>Melhoria do aplicativo: </p>
                       <p>2.1. Utilizamos as informações coletadas para melhorar o aplicativo, personalizar a experiência do usuário, solucionar problemas técnicos e desenvolver novos recursos.</p>
                       <p>Comunicações:</p>
                       <p>2.2. Podemos enviar comunicações, como notificações, atualizações e promoções, desde que o usuário tenha consentido previamente em receber tais comunicações.</p>
                       <p>Compartilhamento de informações:</p>
                       <p>2.3. Não compartilhamos informações pessoais identificáveis do usuário com terceiros, exceto quando exigido por lei ou quando necessário para fornecer serviços relacionados ao aplicativo.</p>
                       <p>3. Segurança das Informações:</p>
                       <p>Tomamos medidas razoáveis para proteger as informações pessoais dos usuários contra acesso não autorizado, uso indevido, alteração ou divulgação. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, e não podemos garantir a segurança absoluta das informações.</p>
                       <p>4. Cookies e Tecnologias Semelhantes:</p>
                       <p>Podemos utilizar cookies e tecnologias semelhantes para coletar informações sobre o uso do aplicativo e melhorar a experiência do usuário. Os usuários têm a opção de aceitar ou recusar o uso de cookies por meio das configurações do aplicativo.</p>
                       <p>5. Alterações no Termo de Privacidade:</p>
                       <p>Reservamo-nos o direito de fazer alterações neste Termo de Privacidade a qualquer momento. As alterações serão comunicadas por meio de atualizações do aplicativo ou outros meios apropriados. O uso continuado do aplicativo após as alterações constitui a aceitação das modificações.</p>
                       <p>6. Contato:</p>
                       <p>Estamos empenhados em garantir a privacidade e a proteção de dados dos usuários e trabalhamos constantemente para melhorar nossas práticas e aderir às melhores políticas de privacidade. Se você tiver alguma dúvida ou preocupação relacionada à privacidade e proteção de dados, entre em contato conosco para obter mais informações.</p>
                       <br></br>
                       <h4>7. Garantias e Limitações de Responsabilidade</h4>
                       <br></br>
                       <p>7.1. O Aplicativo é fornecido "no estado em que se encontra" e "conforme disponível". Não fazemos declarações ou garantias de qualquer tipo, expressas ou implícitas, em relação ao Aplicativo, incluindo, mas não se limitando a, sua disponibilidade, funcionalidade, precisão, confiabilidade ou adequação a um propósito específico.</p>
                       <p>7.2. Não seremos responsáveis por quaisquer danos diretos, indiretos, consequenciais, especiais ou punitivos resultantes do uso ou incapacidade de usar o nosso aplicativo ou dos serviços de saúde prestados pelos estabelecimentos de saúde.</p>
                       <p>7.3. O Dr. Clube não se responsabiliza por quaisquer disputas, reclamações ou responsabilidades decorrentes de serviços prestados pelos provedores de serviços de saúde cadastrados no Aplicativo. Tais disputas devem ser resolvidas diretamente entre o usuário e o provedor de serviços de saúde.</p>
                       <br></br>
                       <h4>8. Links para Sites de Terceiros</h4>
                       <br></br>
                       <p>O nosso aplicativo pode conter links para sites de terceiros que não são operados por nós. Não temos controle sobre o conteúdo ou práticas de privacidade desses sites de terceiros e não nos responsabilizamos por eles.</p>
                       <br></br>
                       <p>Para qualquer dúvida ou preocupação relacionada à privacidade ou a este termo, os usuários podem entrar em contato conosco através dos canais de suporte fornecidos no aplicativo.</p>
                       <br></br>
                       <h4>Privacidade e Proteção de Dados pelo Prestador de Serviços ao Usuário</h4>
                       <br></br>
                       <p>Este Termo de Privacidade descreve como são coletadas, usadas, armazenadas e compartilhadas as informações pessoais dos usuários ao entrar em contato com os Prestadores de serviços:</p>
                       <br></br>
                       <p>1. Coleta de Dados</p>
                       <p>1.1. Os Prestadores podem coletar informações pessoais necessárias para fornecer os serviços solicitados pelos usuários. Isso significa que apenas dados relevantes, proporcionais e estritamente necessários para cumprir o propósito específico do serviço.</p>
                       <p>1.2. Os Prestadores de serviços são exclusivamente responsáveis pelo consentimento dos usuários antes de coletar ou utilizar suas informações pessoais.</p>
                       <p>2. Segurança de Dados:</p>
                       <p>Os Prestadores de serviços são exclusivamente responsáveis por manter medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, uso indevido, divulgação, alteração ou destruição. Implementamos controles técnicos e organizacionais para garantir a segurança dos dados.</p>
                    </div>
                </div>
            </div>
            </>
            )}
            </IsVisible>
        </div>
    </div>
  )
}

export default Politica_de_Privacidade_App;