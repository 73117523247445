import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import { updateEstabelecimento, editarEstabelecimento } from '../../store/modules/estabelecimento/actions';

import {
  Button,
  Spinner,
} from "reactstrap";

const Horarios = () =>{
  const { form, estabelecimento } = useSelector((state) => state.estabelecimento);
  const dispatch = useDispatch();
  const [novoArray, setNovoArray] = useState([]);

  const handleAbreChange = (e, index) => {
    const arrayHorarios = form?.horarios || estabelecimento?.horarios
    const novoArray = [...arrayHorarios];
    novoArray[index] = Object.assign({}, novoArray[index], {abre: e.target.value});
    dispatch(
      updateEstabelecimento({
        form: { ...form, horarios: novoArray}
      })
    )
  }
  const handleFechaChange = (e, index) => {
    const arrayHorarios = form?.horarios || estabelecimento?.horarios
    const novoArray = [...arrayHorarios];
    novoArray[index] = Object.assign({}, novoArray[index], {fecha: e.target.value});
    dispatch(
      updateEstabelecimento({
        form: { ...form, horarios: novoArray}
      })
    )
  }
  const handleAbertoChange = (e, index) => {
    const arrayHorarios = form?.horarios || estabelecimento?.horarios
    const novoArray = [...arrayHorarios];
    novoArray[index] = Object.assign({}, novoArray[index], {aberto: e.target.checked});
    dispatch(
      updateEstabelecimento({
        form: { ...form, horarios: novoArray}
      })
    )
  }

  const onSubmit = async data =>{
    dispatch(
      editarEstabelecimento()
    )
  }
  return(
    <div className="col p-5 overflow-inherit" style={{minHeight: '100vh'}}>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h2 className="mb-4 mt-4 text-muted">Horário de funcionamento</h2>
      </div>
      <div>
        <div className="row mb-3">
          <div className="col-2">
            <strong>Dia</strong>
          </div>
          <div className="col-2">
            <strong>Abre</strong>
          </div>
          <div className="col-2">
            <strong>Fecha</strong>
          </div>
        </div>
        {
          estabelecimento?.horarios.map((e, index) => (
            <div className="row">
              <span className="col-2">{e?.dia}</span>
              <div className="col-2 mb-2">
                <input 
                  type="time" 
                  onChange={(o) => handleAbreChange(o, index)} 
                  value={form.horarios?.[index]?.abre ?? e?.abre}
                  className="form-control"
                  disabled={!(form.horarios?.[index]?.aberto ?? e?.aberto)}
                />
              </div>
              <div className="col-2 mb-2">
                <input 
                  type="time"
                  onChange={(o) => handleFechaChange(o, index)} 
                  value={form.horarios?.[index]?.fecha ?? e?.fecha} 
                  className="form-control"
                  disabled={!(form.horarios?.[index]?.aberto ?? e?.aberto)}
                />
              </div>
              <div className="col-2">
                <div className="custom-control custom-switch">
                  <input 
                    type="checkbox" 
                    className="custom-control-input" 
                    id={`switch${e._id}`} 
                    checked={form.horarios?.[index]?.aberto ?? e?.aberto}
                    onChange={(e) => handleAbertoChange(e, index)}
                  />
                  <label 
                    className="custom-control-label p-0" 
                    for={`switch${e._id}`}
                    >
                      {form.horarios?.[index]?.aberto ?? e?.aberto? 'Aberto': 'Fechado'}
                  </label>
                </div>
              </div>
            </div>
          ))
        }
        <div className="row align-items-center mt-3">
          <div className="col-8">
            {
              form.saving? (
                  <Button className="col-md-4" color="primary">
                    <Spinner size="sm"/>
                  </Button>
              ):
              (
                <Button 
                  className="col-md-4" 
                  color="primary" 
                  type="button"
                  onClick={() => onSubmit()}
                >
                  Salvar horários
                </Button>
              )
            }
          </div>
      </div>
      </div>
    </div>
  );
}

export default Horarios;