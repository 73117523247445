import React, { useState } from 'react';
import {
  Container,
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";


import NoResponsive from '../../components/NoResponsive';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, updateEstabelecimento } from '../../store/modules/estabelecimento/actions';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet-async';
import * as yup from "yup";


const Login = () => {
  const schema = yup.object().shape({
    email: yup.string('insira um email válido!').required('insira um email!').email('insira um email válido!'),
    senha: yup.string('insira uma senha válida!').required('insira uma senha!').min(8, 'insira uma senha com 8 dígitos ou mais!'),
  })
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { form } = useSelector((state) => state.estabelecimento);

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      }
    )
    );
  };
  const { register, handleSubmit, formState: { errors }  } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = () =>{
    if(  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      setIsMobile(true)
     }else{
       dispatch(login())
     }
  }
  
  
  
  return (
    <Container className="mt--9 pb-5">

       <Helmet>
       <title>Login</title>
       <meta name="description" content="Entre no Dr. Clube e comece a receber clientes hoje mesmo! " />
       <link rel='canonical' href='/login' />
    </Helmet>

    <NoResponsive show={isMobile} setShow={setIsMobile}/>
    <Row className="justify-content-center">
      <Col lg="4" md="7" className="mx-auto h-100 mt-5">
        <Card className="bg-secondary border-0 mt-5">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={form.saving ? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Email"
                    autoComplete="new-email"
                    className="form-control"
                    ref={register}
                    {...register("email", {
                      onChange:(e) => setEstabelecimento('email', e.target.value.toLowerCase())
                    })}
                  />
                </InputGroup>
                {errors.email?.type && <small className="validacao">{errors?.email?.message}</small>}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    className="form-control"
                    ref={register}
                    {...register('senha', {
                      onChange: (e) => setEstabelecimento('senha', e.target.value)
                    })}
                  />
                </InputGroup>
                {errors.senha?.type && <small className="validacao">{errors?.senha?.message}</small>}
              </FormGroup>
              <div className="text-center">
                {form.saving ? (
                  <Button className="my-4" color="primary">
                    <Spinner size="sm"/>
                  </Button>
                ) : (
                  <Button className="my-4" color="primary" type="submit">
                    Entrar
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-primary" to="/recuperar-senha">
              Recuperar senha
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link className="text-primary" to='/cadastro'>
              Criar nova conta
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
  
  );
};

export default Login;
