import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


import Helmet from 'react-helmet'
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';

const DefaultLayout = ({children}) =>{
  const { estabelecimento } = useSelector(state => state.estabelecimento);
  const location = useLocation();
  return(
    <div className="container-fluid h-100">
      <Helmet title={`Dr. Clube - ${
          location.pathname === '/servicos-produtos' ? 'Serviços' :
          location.pathname === '/Dashboard'? 'Dashboard' :
          location.pathname === '/Horarios'? 'Horários' :
          location.pathname === '/Convenios'? 'Convenios' :
          location.pathname === '/Pagamentos'? 'Pagamentos' :
          location.pathname === '/Termo'? 'Termos de uso' :
          estabelecimento?.nomeFantasia
        }`}
      />
      <div className="row h-100">
        <Header signed={true}/>
        <Sidebar />
        {children}
      </div>
    </div>
  )
}
export default DefaultLayout;