import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import IsVisible from 'react-is-visible';

function PoliticaCookies() {
  return (
    <>
    <Helmet>
     <title>Política De Cookies</title>
     <meta name="description" content="Política De Cookies do Dr. Clube" />
     <link rel='canonical' href='/politicacookies' />
  </Helmet>
    <div className="prising_area">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-13 container-fluid">
                    <div>
                          <p>Ao utilizar este site, você concorda com o uso de cookies de acordo com esta política de cookies. Se você não concorda com o uso de cookies, por favor, não utilize este site.</p>
                        <br></br>
                          <p>Este site utiliza cookies e outras tecnologias similares para garantir o seu correto funcionamento e aprimorar sua experiência como usuário. Ao utilizar este site, você concorda com o uso dessas tecnologias de acordo com esta política de cookies.</p>
                        <br></br>
                        <br></br>
                          <h4>O que são cookies?</h4>
                        <br></br>
                          <p>Cookies são pequenos arquivos de texto armazenados em seu dispositivo quando você acessa um site. Eles são usados para lembrar as preferências do usuário, ajudar na navegação e coletar informações sobre o uso do site.</p>
                        <br></br>
                          <h4>Como usamos os cookies?</h4>
                        <br></br>
                          <p>Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que você usa.</p>
                        <br></br>
                          <h4>Como gerenciar cookies?</h4>
                        <br></br>
                          <p>Você pode gerenciar cookies em seu navegador, incluindo desativar ou bloquear cookies completamente. No entanto, tenha em mente que alguns recursos do site podem não funcionar corretamente sem cookies.</p>
                        <br></br>
                          <h2>Cookies que definimos:</h2>
                        <br></br>
                          <h4>Cookies essenciais</h4>
                        <br></br>
                          <p>São cookies necessários para o funcionamento do site e para permitir que você navegue e utilize seus recursos.</p>
                        <br></br>
                          <h4>Cookies de desempenho</h4>
                        <br></br>
                          <p> São cookies que coletam informações sobre a forma como os usuários interagem com o site, incluindo quais páginas são visitadas com mais frequência e se há erros. Essas informações são usadas para melhorar o desempenho do site e aprimorar a experiência do usuário.</p>
                        <br></br>
                          <h4>Cookies de funcionalidade</h4>
                        <br></br>
                          <p>São cookies que lembram as suas preferências, como idioma e localização, e fornecem recursos aprimorados e personalizados.</p>
                        <br></br>
                          <h4>Cookies relacionados à conta</h4>
                        <br></br>
                          <p>Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.</p>
                        <br></br>
                          <h4>Cookies relacionados ao login</h4>
                        <br></br>
                          <p>Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</p>
                        <br></br>
                          <h4>Cookies relacionados a boletins por e-mail</h4>
                        <br></br>
                          <p>Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.</p>
                        <br></br>
                          <h4>Pedidos processando cookies relacionados</h4>
                        <br></br>
                          <p>Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.</p>
                        <br></br>
                          <h4>Cookies relacionados a pesquisas</h4>
                        <br></br>
                          <p>Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.</p>
                        <br></br>
                          <h4>Cookies relacionados a formulários</h4>
                        <br></br>
                          <p>Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.</p>
                        <br></br>
                          <h4>Cookies de preferências do site</h4>
                        <br></br>
                          <p>Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.</p>
                        <br></br>
                          <h4>Cookies de publicidade</h4>
                        <br></br>
                          <p>São cookies que coletam informações sobre a sua atividade de navegação para fornecer anúncios mais relevantes para você.</p>
                        <br></br>
                          <h4>Cookies de Terceiros</h4>
                        <br></br>
                          <h5>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.</h5>
                        <br></br>
                          <p>Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.</p>
                        <br></br>
                          <h5>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</h5>
                        <br></br>
                          <p>1 - As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.</p>
                          <p>2 - Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.</p>
                          <p>3 - À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analizar nossos custos de publicidade e produtos para garantir o melhor preço possível.</p>
                        <br></br>
                          <h4>Compromisso do Usuário</h4>
                        <br></br>
                          <h5>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Doutor Clube oferece no site e com caráter enunciativo, mas não limitativo:</h5>
                        <br></br>
                          <p>1 - Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</p>
                          <p>2 - Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</p>
                          <p>3 - Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Doutor Clube, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</p>
                        <br></br>
                          <h4>Alterações nesta política de cookies</h4>
                        <br></br>
                          <p>Podemos atualizar esta política de cookies a qualquer momento. A versão mais recente estará sempre disponível em nosso site.</p>
                        <br></br>
                          <h4>Mais informações</h4>
                        <br></br>
                          <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>
                        <br></br>
                          <h5>Esta política é efetiva a partir de Janeiro/2023.</h5>

                    </div>
                </div>
            </div>
           
          
                       </>
            )}
            </IsVisible>
        </div>
    </div>
    </>
  )
}

export default PoliticaCookies;