import types from './types';
import produce from 'immer';

const INITIAL_STATE = { 
  estabelecimento: JSON.parse(localStorage.getItem('@auth:estabelecimento')),
  accessToken: JSON.parse(localStorage.getItem('@auth:accessToken')),
  form: {
    saving: false
  },
};


function estabelecimento(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_ESTABELECIMENTO: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.estabelecimento };
        return draft;
      });
    }
    default:
      return state;
  }
}

export default estabelecimento;
