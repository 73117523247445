import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { Container } from './styles';

function Contato() {
  return (
    <>
    <Helmet>
     <title>Contato</title>
     <meta name="description" content="Nosso especialista está pronto para lhe atender e apresentar todas as soluções disponíveis." />
     <link rel='canonical' href='/contato' />
     
  </Helmet>
    <section className="contact-section">
        <div className="container">
            <div className="row">
                
                {/* Campos Formulario para contato

                 <div className="col-lg-8">
                    <form className="form-contact contact_form" action="contact_process.php" method="post" id="contactForm" novalidate="novalidate">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" Nome"></textarea>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <input className="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Entrar com nome" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <input className="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <input className="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Digite o assunto" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <button type="submit" className="button button-contactForm boxed-btn">Enviar</button>
                        </div>
                    </form>
                </div>
                */}
               
               <div className="col-lg-3 offset-lg-1" style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="media contact-info" style={{ alignItems: 'center', marginBottom: '20px' }}>
        <span className="contact-info__icon"><i className="ti-home"></i></span>
        <div className="media-body" style={{ marginLeft: '10px' }}>
            <h3 style={{ margin: '0' }}>Buttonwood, California.</h3>
            <p style={{ margin: '0' }}>Rosemead, CA 91770</p>
        </div>
    </div>
    <div className="media contact-info" style={{ alignItems: 'center', marginBottom: '20px' }}>
        <span className="contact-info__icon"><i className="ti-tablet"></i></span>
        <div className="media-body" style={{ marginLeft: '10px' }}>
            <h3 style={{ margin: '0' }}>91 9 8234-1049</h3>
            <p style={{ margin: '0' }}>Seg a Sex 8h às 18h</p>
        </div>
    </div>
    <div className="media contact-info" style={{ alignItems: 'center' }}>
        <span className="contact-info__icon"><i className="ti-email"></i></span>
        <div className="media-body" style={{ marginLeft: '10px' }}>
            <h3 style={{ margin: '0' }}>contato@drclube.com</h3>
            <p style={{ margin: '0' }}>Envie-nos um e-mail a qualquer momento!</p>
        </div>
    </div>
</div>

            </div>
        </div>
    </section>
    </>
  )
}

export default Contato;