import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import {

  Uploader,
  Icon,
  Button as RButton,
  Modal,
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';

import { notification } from '../../services/rsuite';

import { useDispatch, useSelector } from 'react-redux';
import { 
  editarEstabelecimento,
  updateEstabelecimento, 
  deletarEstabelecimento, 
  getEstabelecimento, 
  removeArquivo
} from '../../store/modules/estabelecimento/actions';

import UserHeader from '../../components/UserHeader';
import api from '../../services/api';
import util from '../../services/util';

import './styles.css'


const Perfil = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { form, estabelecimento } = useSelector((state) => state.estabelecimento);
  const [filePreview, setFilePreview] = useState(`${util.AWS.bucketURL}/${estabelecimento?.logo}`);

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      })
    );
  };

  const setCategorias = (e) => {
    const { value, checked  } = e.target
    const { categorias } = form;
    if(checked){
      dispatch(
        updateEstabelecimento({
          form: { ...form, categorias: categorias? [...categorias, value] : [value]}
        })
      );
    }else{
      dispatch(
        updateEstabelecimento({
          form: { ...form, categorias: categorias?.filter((e) => e !== value)}
        })
      );
    }
  };

  const setEndereco = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, endereco: {...estabelecimento.endereco, ...form.endereco, [key]: value } }
      })
    );
  };

  const onSubmit = async data =>{
    const file = data.file[0];

    if(!file || /(\.jpg|\.jpeg|\.png)$/i.test(file?.name)){
      if(file){
        setEstabelecimento("file", file)
      }
      dispatch(editarEstabelecimento())
    }
    else{
      return notification('error', { placement: 'topStart',title: 'Ops!', description: "O formato de imagem inserido não é aceito"});
    }
  }

  const getCep = async () =>{
    const { data: res } = await api.get(
      `/estabelecimento/endereco/${form?.endereco?.cep}`
    );

    if(res.status == "OK"){
      dispatch(
        updateEstabelecimento({
          estabelecimento: {
            ...estabelecimento,
            endereco: {
              ...form.endereco,
              /* cidade: res?.results[0]?.address_components[3]?.long_name,
              bairro: res?.results[0]?.address_components[2]?.long_name,
              estado: res?.results[0]?.address_components[4]?.short_name,
              endereco: res?.results[0]?.address_components[1]?.long_name, */
              geo: {
                latitude: res?.results[0]?.geometry?.location?.lat,
                longitude: res?.results[0]?.geometry?.location?.lng
              }
            }
          },
          form: {
            ...form,
            endereco: {
              ...form.endereco,
             /*  cidade: res?.results[0]?.address_components[3]?.long_name,
              bairro: res?.results[0]?.address_components[2]?.long_name,
              estado: res?.results[0]?.address_components[4]?.short_name,
              endereco: res?.results[0]?.address_components[1]?.long_name, */
              geo: {
                latitude: res?.results[0]?.geometry?.location?.lat,
                longitude: res?.results[0]?.geometry?.location?.lng
              }
            }
          }
        })
      );
    }
  }

  const onSubmitImage = async file =>{
    var formData = new FormData()

    formData.append('file', file);

    const { data } = await api.post('/estabelecimento/adicionarImagem', formData,  {"Content-Type": "multipart/form-data"});
    
    if(data?.error){
      return notification('error', { placement: 'topStart',title: 'Ops!', description: data?.message});
    }

    dispatch(getEstabelecimento())
  }

  useEffect(() => {
    const fetchOpcoes = async () =>{
      const response = await api.get(`/estabelecimento/enderecos`);
      if(response.data.error){
        return false;
      }
      setOpcoes(response.data);
    }
    fetchOpcoes();
    dispatch(getEstabelecimento());
  }, [])
  
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onDelete = () =>{
    dispatch(deletarEstabelecimento());
  }

  const [opcoes, setOpcoes] = useState({cidades: [], estados: []});
  
  const newURL = 'https://doutor-clube-dev.s3.amazonaws.com/convenios/Avatar+Icon.png';
  
  return(
    <>
    <Modal
      show={confirmDelete}
      onHide={() => setConfirmDelete(false)}
      size="xs"
    >
      <Modal.Body>
        <Icon
          icon="remind"
          style={{
            color: '#ffb300',
            fontSize: 25,
          }}
        />
         &nbsp; Tem certeza que deseja excluir a conta? Essa ação será irreversível!
        </Modal.Body>
        <Modal.Footer>
          <RButton loading={form.saving} onClick={() => onDelete()} color="red">
            Sim, tenho certeza!
          </RButton>
          <RButton
            onClick={() => setConfirmDelete(false)}
            appearance="subtle"
          >
            Cancelar
          </RButton>
        </Modal.Footer>
      </Modal>
    <div className="col p-0 pb-4 profile">
      <UserHeader />
  	  <Container className="mt--7" fluid>
        <Row>       
          <Col className="order-xl-1 mx-auto" xl="10">
            <Card className="bg-secondary shadow">
              <Form onSubmit={form.saving? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
              <CardHeader className="border-0 card-profile-image img__form mb-4 bg-transparent">
                  <input 
                    type="file"
                    id="file"
                    name="file"
                    className="card-profile-image"
                    accept=".jpg, .jpeg, .png"
                    {...register("file", {
                      onChange: e => e.target.files[0] && setFilePreview(URL.createObjectURL(e.target.files[0]))
                    })}
                  />
                  <img
                    alt="..."
                    className="input-img-img"
                    src={filePreview || newURL}
                  />
                  <Row className="align-items-center p-3">
                      <Col xs="8">
                        <h3 className="mb-0">Minha conta</h3>
                      </Col>
                  </Row>
              </CardHeader>
              <CardBody>
                  <br/>
                  <h6 className="heading-small text-muted mb-4">
                  Informações da Conta
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nomeResponsável"
                          >
                            Nome completo do responsável da conta *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nomeResponsável"
                            placeholder="Nome do responsável"
                            type="text"
                            defaultValue={estabelecimento?.nomeResponsavel}
                            onChange={e =>  setEstabelecimento('nomeResponsavel', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-telefoneResponsavel"
                          >
                            Telefone do responsável *
                          </label>
                          <TelefoneBrasileiroInput
                            className="form-control form-control-alternative"
                            id="input-telefoneResponsavel"
                            placeholder="Telefone do responsável"
                            type="text"
                            temDDD
                            separaDDD
                            value={form?.telefoneResponsavel !== undefined ? form?.telefoneResponsavel  : estabelecimento?.telefoneResponsavel}
                            onChange={e =>  setEstabelecimento('telefoneResponsavel', e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpfResponsavel"
                          >
                            Cpf do responsável *
                          </label>
                          <CpfCnpj
                            className="form-control form-control-alternative"
                            id="input-cpfResponsavel"
                            placeholder="Cpf do responsável"
                            value={form?.cpfResponsavel !== undefined ? form?.cpfResponsavel :  estabelecimento?.cpfResponsavel}
                            type="text"
                            onChange={e => setEstabelecimento('cpfResponsavel', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-site"
                          >
                            Email da conta *
                          </label>
                          <span class="custom-tooltip">
                          <i className="fas fa-question-circle fa-sm tooltip-icon" />
                         <span class="tooltip-text">Atenção: Este é o seu e-mail de acesso à conta. Para atualizá-lo, insira um novo e salve as alterações.</span>
                         </span>
                          <Input
                            className="form-control form-control-alternative"
                            defaultValue={estabelecimento?.email}
                            id=""
                            placeholder="Email"
                            type="text"
                            temDDD
                            separaDDD
                            onChange={(e) => setEstabelecimento('email', e.target.value?.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <br/>
                  <h6 className="heading-small text-muted mb-0">
                   (AS informações abaixo serão visíveis aos usuários)
                  </h6>
                  <br/>
                  <h6 className="heading-small text-muted mb-4">
                  Informações do negócio ou profissional
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome Empresárial *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.nomeEmpresarial}
                            id="input-nomeEmpresarial"
                            placeholder="Nome Empresárial"
                            type="name"
                            onChange={(e) => setEstabelecimento('nomeEmpresarial', e.target.value?.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cnpj"
                          >
                            Cnpj *
                          </label>
                          <CpfCnpj
                            className="form-control form-control-alternative"
                            id="input-cnpj"
                            placeholder="Cnpj"
                            value={form?.cnpj !== undefined ? form?.cnpj : estabelecimento?.cnpj}
                            type="text"
                            onChange={e =>  setEstabelecimento('cnpj', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nomeFantasia"
                          >
                            Nome Fantasia *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.nomeFantasia}
                            onChange={(e) => setEstabelecimento('nomeFantasia', e.target.value.trim())}
                            id="input-nomeFantasia"
                            placeholder="Nome fantasia"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-descricao"
                        >
                          Faça uma breve descrição da empresa *
                        </label>
                        <Input
                         rows="5"
                          className="form-control-alternative"
                          defaultValue={estabelecimento?.descricao}
                          value={form?.decricao}
                          id="input-descrição"
                          placeholder="Descrição"
                          type="textarea"
                          onChange={e => setEstabelecimento('descricao', e.target.value.trim())}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                 
                  <h6 className="heading-small text-muted mb-4">
                  Para atendimento e agendamento do cliente  <span class="custom-tooltip">
                          <i className="fas fa-question-circle fa-sm tooltip-icon" />
                         <span class="tooltip-text"> O campo telefone e whatsapp é essencial para agendamentos, facilitando a comunicação direta do cliente com a empresa.</span>
                         </span>
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Telefone para agendamento *
                          </label>
                         
                          <TelefoneBrasileiroInput
                            value={form?.telefone !== undefined ? form?.telefone :  estabelecimento?.telefone}
                            placeholder="Telefone"
                            temDDD
                            separaDDD
                            className="form-control form-control-alternative"
                            onChange={e =>  setEstabelecimento('telefone', e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Whatsapp para agendamento *
                          </label>
                         
                          <TelefoneBrasileiroInput
                            value={form?.whatsapp !== undefined ? form?.whatsapp : estabelecimento?.whatsapp}
                            placeholder="Whatsapp"
                            temDDD
                            separaDDD
                            className="form-control form-control-alternative"
                            onChange={e => setEstabelecimento('whatsapp', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                  Endereço para receber o cliente <span class="custom-tooltip">
                          <i className="fas fa-question-circle fa-sm tooltip-icon" />
                         <span class="tooltip-text"> O campo de endereço é essencial para o cliente, facilitando a localização do atendimento.</span>
                         </span>
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Logradouro *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.endereco}
                            id="input-address"
                            placeholder="Avenida, rua, passagem, etc"
                            type="text"
                            onChange={e => setEndereco('endereco', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Complemento *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.complemento}
                            id="input-address"
                            placeholder="Edifício, sala, andar, etc"
                            type="text"
                            onChange={e => setEndereco('complemento', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cep"
                          >
                            Cep *
                          </label>
                          <InputMask
                            placeholder="Cep"
                            className="form-control form-control-alternative"
                            mask="99999-999"
                            type="text"
                            autoComplete="new-p"
                            onChange={(e) => setEndereco('cep', e.target.value)}
                            onBlur={() => getCep()}
                            value={form?.endereco?.cep || estabelecimento?.endereco?.cep  || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-district"
                          >
                            Bairro *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.bairro}
                            id="input-district"
                            placeholder="Bairro"
                            type="text"
                            onChange={e => setEndereco('bairro', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Nùmero *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.numero}
                            id="input-numero"
                            placeholder="Número"
                            type="number"
                            onChange={(e) => setEndereco('numero', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-estado"
                          >
                            Estado *
                          </label>
                          <select
                            className="form-control-alternative form-control"
                            value={form?.endereco?.estado || estabelecimento?.endereco?.estado || ""}
                            onChange={e => setEndereco('estado', e.target.value.trim())}
                          >
                          <option value="">Escolher estado</option>
                          {
                              opcoes.estados
                              ?.map(e =>(
                                <option value={e?.sigla} key={e?.sigla}>{e?.sigla}</option>
                              ))
                            }
                        </select>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Cidade * 
                          </label>
                          <select
                            className="form-control-alternative form-control"
                            value={form?.endereco?.cidade || estabelecimento?.endereco?.cidade || ""}
                            onChange={e => setEndereco('cidade', e.target.value)}
                            disabled={!(estabelecimento?.endereco?.estado || form?.endereco?.estado)}
                          >
                            <option value="">Escolher cidade</option>
                            {
                              opcoes.cidades
                              ?.filter(e => e.estado === form?.endereco?.estado || e.estado === estabelecimento?.endereco?.estado)
                              ?.map(e =>(
                                <option value={e?.nome} key={e?.nome}>{e?.nome}</option>
                              ))
                            }
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Escolha as categorias do seu negócio  <span class="custom-tooltip">
                    <i className="fas fa-question-circle fa-sm tooltip-icon" />
                    <span class="tooltip-text">As categorias são responsáveis por categorizar o seu negócio dentro do aplicativo.</span>
                    </span>
                  </h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <div className="d-flex flex-wrap ml-3">
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Salão" 
                            name="categoria" 
                            value="Salão"
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)}
                            checked={form?.categorias?.includes("Salão")}
                          />
                          <label className="form-control-label m-0" for="Salão">Salão</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Barberia" 
                            name="categoria"
                            value="Barbearia" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Barbearia")}
                          />
                          <label for="Barberia" className="form-control-label m-0">Barbearia</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Consulta" 
                            name="categoria"
                            value="Consulta" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Consulta")}
                          />
                          <label for="Consulta" className="form-control-label m-0">Consulta</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Atendimento veterinário" 
                            name="categoria"
                            value="Atendimento veterinário" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Atendimento veterinário")}
                          />
                          <label for="Atendimento veterinário" className="form-control-label m-0">Atendimento veterinário</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Exame" 
                            name="categoria"
                            value="Exame" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Exame")}
                          />
                          <label for="Exame" className="form-control-label m-0">Exame</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Estética" 
                            name="categoria"
                            value="Estética" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Estética")}
                          />
                          <label for="Estética" className="form-control-label m-0">Estética</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Barberia" 
                            name="categoria"
                            value="Terapia" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Terapia")}
                          />
                          <label for="Terapia" className="form-control-label m-0">Terapia</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Servicos Pet" 
                            name="categoria"
                            value="Servicos Pet" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Servicos Pet")}
                          />
                          <label for="Servicos Pet" className="form-control-label m-0">Serviços Pet</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Medicina do trabalho" 
                            name="categoria"
                            value="Medicina do trabalho" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Medicina do trabalho")}
                          />
                          <label for="Medicina do trabalho" className="form-control-label m-0">Medicina do trabalho</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Academia" 
                            name="categoria"
                            value="Academia" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Academia")}
                          />
                          <label for="Academia" className="form-control-label m-0">Academia</label>
                        </div>
                        <div className="col-4 col-lg-3 mt-1">
                          <Input 
                            type="checkbox" 
                            id="Odonto" 
                            name="odonto"
                            value="Odonto" 
                            className="form-check-input"
                            onChange={(e) => setCategorias(e)} 
                            checked={form?.categorias?.includes("Odonto")}
                          />
                          <label for="Odonto" className="form-control-label m-0">Odonto</label>
                        </div>
                      </div>
                    </FormGroup>
                    <br/>
                    {
                      form?.imagens && (
                        <>
                        <h6 className="heading-small text-muted">
                          imagens do local do seu estabelecimento (opcional)
                          <span class="custom-tooltip">
                              <i className="fas fa-question-circle fa-sm tooltip-icon" />
                              <span class="tooltip-text">Adicione imagens internas e externas do seu estabelecimento. Isso vai atrair mais clientes para o seu estabelecimento.</span>
                          </span>
                        </h6>
                          <FormGroup>
                            <Uploader
                              multiple
                              listType="picture"
                              defaultFileList={
                                form?.imagens
                                ?.map((s, i) => ({
                                  name: s,
                                  fileKey: i,
                                  url: `${util.AWS.bucketURL}/${s}`,
                                }))
                              }
                              autoUpload={false}
                              onChange={(files) => {
                                const arquivos = files
                                .filter(f => f.blobFile)
                                .map(f => f.blobFile);
                                if(!!arquivos[arquivos.length - 1]){
                                  onSubmitImage(arquivos[arquivos.length - 1])
                                }else{
                                  dispatch(getEstabelecimento())
                                }
                              }}
                              onRemove={file => {
                                dispatch(removeArquivo(file.name))
                              }}
                            >
                              <button type="button">
                                <Icon icon="camera-retro" size="lg" />
                              </button>
                            </Uploader>
                          </FormGroup>
                        </>
                      )
                    }
                    <Row className="align-items-center">
                      <Col className="offset-8 text-right" xs="4">
                        {
                          form.saving? (
                              <Button className="col-md-9" color="primary">
                                <Spinner size="sm"/>
                              </Button>
                            ): (
                              <Button className="col-md-9" color="primary" type="submit">
                                Salvar informações
                              </Button>
                          )
                        }
                      </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    <Col className="offset-8 text-right" xs="4">
                      {
                        form.saving? (
                            <Button className="col-md-9" color="danger">
                              <Spinner size="sm"/>
                            </Button>
                          ): (
                            <Button className="col-md-9" color="danger" type="button" onClick={() => setConfirmDelete(true)}>
                              Excluir Conta
                            </Button>
                        )
                      }
                    </Col>
                  </Row>
                </div>
              </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  )
}
export default Perfil;