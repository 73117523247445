import types from './types';

export function getEstabelecimento() {
  return { type: types.GET_ESTABELECIMENTO};
}

export function updateEstabelecimento(estabelecimento) {
  return { type: types.UPDATE_ESTABELECIMENTO, estabelecimento };
}
export function login() {
  return { type: types.LOGIN_ESTABELECIMENTO };
}

export function cadastrar() {
  return { type: types.CADASTRAR_ESTABELECIMENTO };
}
export function sair(){
  return { type: types.SAIR_ESTABELECIMENTO }
}
export function editarEstabelecimento() {
  return { type: types.EDITAR_ESTABELECIMENTO }
}
export function deletarEstabelecimento() {
  return { type: types.DELETAR_ESTABELECIMENTO }
}
export function removeArquivo(arquivo) {
  return { type: types.REMOVERARQUIVO_ESTABELECIMENTO, arquivo };
}