import { 
  Button,
  Spinner
} from 'reactstrap';

import { useState, useEffect } from 'react';

import {
  Drawer,
} from 'rsuite';

import { updateEstabelecimento, editarEstabelecimento } from '../../store/modules/estabelecimento/actions';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from '../../services/rsuite';

import api from '../../services/api';

import './styles.css'

const Convenios = () =>{
  const { form, estabelecimento } = useSelector((state) => state.estabelecimento);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [convenios, setConvenios] = useState([]);
  
  /* const convenios = [
    {
      nome: "Unimed",
      logo: 'https://iili.io/HcVeWxa.jpg'
    },
    {
      nome: "Hapvida",
      logo: 'https://iili.io/HcVkM22.jpg'
    },
    {
      nome: "Amil",
      logo: 'https://iili.io/HcVkCXa.jpg'
    },
  ] */
  const remove = value =>{
    value = JSON.parse(value || '')
    dispatch(
      updateEstabelecimento({
        form: { ...form, convenios: estabelecimento?.convenios?.filter((e) => e.nome !== value.nome)}
      })
    );
    dispatch(
      editarEstabelecimento()
    )
  }

  const add = (value) => {
    const { convenios } = form;
    dispatch(
      updateEstabelecimento({
        form: { ...form, convenios: estabelecimento?.convenios? [...estabelecimento?.convenios, value] : [value]}
      })
    );
  };
  const onSubmit = async data =>{
    dispatch(
      editarEstabelecimento()
    )
    setShow(false)
  }

  useEffect(() =>{
    const fetchConvenios = async () =>{
      const { data } = await api.get(`/convenio/lista`);    
      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
        });
        return false;
      }                
      setConvenios(data?.convenios);
    }            
    fetchConvenios();
  }, []);
      
  return(
    <>
    <Drawer
        show={show}
        size="sm"
        onHide={() => setShow(false)}
    >
      <Drawer.Body>
      {
        convenios?.length >= estabelecimento?.convenios?.length ?(
          <>
            <div className="form-group col-12 mt-3">
              <b className="servico-label">Convênios</b>
              <select
                className="form-control mt-2"
                value={form?.convenio}
                onChange={(e) => add(JSON.parse(e.target.value || ''))}
              >
                <option>Escolha um convênio</option>
                {
                  convenios
                  ?.filter(e => !estabelecimento?.convenios?.map(e => e?.nome).includes(e.nome))
                  ?.map(e => (
                    <option value={JSON.stringify(e)}>{e?.nome}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12">
              {
                form.saving? (
                    <Button className="col-md-12" color="primary">
                      <Spinner size="sm"/>
                    </Button>
                ):
                (
                  <Button 
                    className="col-md-12" 
                    color="primary"
                    type="button"
                    onClick={() => onSubmit()}
                  >
                    Adicionar convênio
                  </Button>
                )
              }
            </div>
          </>
         ):
         (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <strong>Você já adicionou todos os convênios disponíveis.</strong>
          </div>
         )
      }
      </Drawer.Body>
      </Drawer>
      <div className="col p-5 overflow-inherit convenios" style={{minHeight: '100vh'}}>
      <div className="alert alert-info d-flex align-items-center border-0" role="alert" style={{ backgroundColor: '#E6F7FF' }}>
        <span
          className="mr-2 d-flex justify-content-center align-items-center rounded-circle"
          style={{ width: '30px', height: '30px', backgroundColor: '#1890FF', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold', aspectRatio: '1' }}
        >
        !
       </span>
       <p>Apenas convênios firmados entre o seu estabelecimento e a operadora ou seguradora de saúde são permitidos. Convênio por reembolso e proibido.</p>
       </div>

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h2 className="mb-4 mt-4 text-muted">Convênios</h2>
        <div className="col-md-2">
            <Button 
              className="" 
              color="primary" 
              type="button"
              onClick={() => setShow(true)}
            >
              Novo Convênio
            </Button>
          </div>
        </div>
      <div className="">
      { 
        estabelecimento?.convenios.map(e => (
          <div className="row col-12 mt-1 border-bottom border-black item" key={e?.nome}>
            <div className="column">
              <img 
                src={e.logo}
                className=""
                />
            </div>
            <div className="column">
              <h5 className="ml-2 mr-3">{e?.nome}</h5>
            </div>
              {
               form.saving? (
                <Button 
                  color="danger" 
                  type="button"
                >
                  <Spinner size="sm"/>
                </Button>
              ):(
                <Button 
                  color="danger" 
                  type="button"
                  onClick={() => remove(JSON.stringify(e))}
                >
                  Excluir
                </Button>
              )
            }
          </div>
        ))
      }
    </div>
    </div>
    </>
  )
}
export default Convenios;