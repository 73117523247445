import { Navigate, useParams } from 'react-router-dom';


export default () =>{
  const { id } = useParams();

  if(  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    const url = `com.drclube://Servicos?id=${id}`;
    window.location.href = url;
  }
  else{
    return(
      <Navigate to="/"/>
    )
  }
};