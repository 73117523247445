import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import types from './types';

import api from '../../../services/api';
import { notification } from '../../../services/rsuite';
import { updateEstabelecimento, getEstabelecimento as get } from './actions';

export function* getEstabelecimento(){
  const { estabelecimento, form } = yield select((state) => state.estabelecimento);

  try {
    const { data: res } = yield call(
      api.get,
      `/estabelecimento/${estabelecimento?._id}`
    );

    if (res.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: res.message,
      });
      return false;
    }

    yield put(updateEstabelecimento({ estabelecimento: res.estabelecimento, form: { 
      ...form,
      categorias: form?.categorias || res?.estabelecimento?.categorias,
      imagens: res.estabelecimento?.imagens
    }}));
    
    localStorage.setItem('@auth:estabelecimento', JSON.stringify(res.estabelecimento));
  } catch (err) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message,
    });
  }
}
export function* logIn() {
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } }));
  try {
    const { data: res } = yield call(api.post, '/auth/login', form);

    if(res.error){
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message.toString() || res.toString()});
      return false;
    }
    localStorage.setItem('@auth:estabelecimento', JSON.stringify(res.estabelecimento?._doc));
    localStorage.setItem('@auth:accessToken', JSON.stringify(res?.estabelecimento?.accessToken));
    /* yield put(updateEstabelecimento({estabelecimento: res.estabelecimento._doc, accessToken: res?.estabelecimento?.accessToken, form: { ...form, saving: false }})) */
    window.location.reload();
    return false;
  }catch(err){
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message || err
    });
  }
}

export function* cadastrar() {
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } })); 
  try{
    var formData = new FormData();
    formData.append('file', form?.file[0]);
    formData.append('senha', form?.senha);
    formData.append('email', form?.email?.toLowerCase());
    formData.append('nomeEmpresarial', form?.nomeEmpresarial);
    formData.append('nomeFantasia', form?.nomeFantasia);
    formData.append('cnpj', form?.cnpj);
    formData.append('latitude', form?.geo?.coords?.latitude);
    formData.append('longitude', form?.geo?.coords?.longitude);


    const { data: res } = yield call(api.post, '/auth/cadastro', formData,  { "Content-Type": "multipart/form-data"});
    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message});
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Conta Criada!', description: "faça login"});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
    return false;
  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err.message || err});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
  }
}
export function* sair() {
  const { form } = yield select((state) => state.estabelecimento);
  try{
    localStorage.removeItem("@auth:estabelecimento");
    localStorage.removeItem("@auth:accessToken");
    yield put(updateEstabelecimento({estabelecimento: {}, accessToken: '', form: { ...form, saving: false }}))

  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err});
  }
}
export function* editar(){

  const { form, estabelecimento } = yield select((state) => state.estabelecimento);

  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } }));

  try{
    const formData = new FormData();

    formData.append('file', form.file);
    formData.append("estabelecimento", JSON.stringify(form));

    const { data: res } = yield call(api.put, `/estabelecimento/${estabelecimento?._id}`, formData, {"Content-Type": "multipart/form-data"});

    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message});
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      return false;
    }

    notification('success', { placement: 'topStart',title: 'Pronto', description: "Seus novos dados foram salvos!"});
    yield put(updateEstabelecimento({estabelecimento: res.estabelecimento , form: { ...form, saving: false } }));
    localStorage.setItem('@auth:estabelecimento', JSON.stringify(res.estabelecimento));
    return false;
  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
  }
}
export function* deletar(){
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } })); 
  try{
    const { data: res } = yield call(api.delete, `/estabelecimento`);
    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message});
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto', description: res.message});
    localStorage.removeItem("@auth:estabelecimento");
    localStorage.removeItem("@auth:accessToken");
    yield put(updateEstabelecimento({estabelecimento: {}, accessToken: '', form: { ...form, saving: false } }));
    
    return false;
  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err.message || err});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
  }
}
export function* removeArquivo(payload) {
  try {
    const { data: res } = yield call(api.post, `/estabelecimento/removerImagem`, {
      arquivo: payload.arquivo,
    });

    if (res.error) {
      // ALERT DO RSUITE
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: res.message,
      });
      return false;
    }
    yield put(get());
  } catch (err) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message,
    });
    

  }
}
export default all([
  takeLatest(types.GET_ESTABELECIMENTO, getEstabelecimento),
  takeLatest(types.CADASTRAR_ESTABELECIMENTO, cadastrar),
  takeLatest(types.LOGIN_ESTABELECIMENTO, logIn),
  takeLatest(types.SAIR_ESTABELECIMENTO, sair),
  takeLatest(types.EDITAR_ESTABELECIMENTO, editar),
  takeLatest(types.DELETAR_ESTABELECIMENTO, deletar),
  takeLatest(types.REMOVERARQUIVO_ESTABELECIMENTO, removeArquivo)
]);