import { useState, useEffect } from 'react';
import './styles.css';

import { Message } from 'rsuite';
import LogoPexels from '../../assets/img/pexels.png';

import api from '../../services/api';

export default () => {
  const [images, setImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('medical appointment');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = 'dr. Clube.jfif';
        downloadLink.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchImages = async (query, pageNumber) => {
    const response = await api.get(`/pexels/search?query=${query || 'medical appointment'}&per_page=12&page=${pageNumber || 0}`);
    
    const data = response?.data?.data;

    setImages(data?.photos);

    setTotalPages(Math.ceil(data?.total_results / 12));
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setPage(1);
    setSearchTerm(event.target.value);
    fetchImages(event.target.value, 1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchImages(searchTerm, newPage);
  }
  
  useEffect(() => {
    fetchImages(searchTerm, 1)
  }, []);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5;
    const halfMaxPageNumbers = Math.floor(maxPageNumbers / 2);

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (page <= halfMaxPageNumbers) {
      for (let i = 1; i <= maxPageNumbers; i++) {
        pageNumbers.push(i);
      }
    } else if (page >= totalPages - halfMaxPageNumbers) {
      for (let i = totalPages - maxPageNumbers + 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = page - halfMaxPageNumbers; i <= page + halfMaxPageNumbers; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  }

  return (
    <div className="col p-5 pt-0 overflow-inherit banco-de-imagens" style={{minHeight: '100vh'}} id="top">
         <div className="alert alert-info d-flex align-items-center border-0" role="alert" style={{ backgroundColor: '#E6F7FF' }}>
        <span
          className="mr-2 d-flex justify-content-center align-items-center rounded-circle"
          style={{ width: '30px', height: '30px', backgroundColor: '#1890FF', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold', aspectRatio: '1' }}
        >
        !
       </span>
       <p>Dica: realizar pesquisas em inglês pode proporcionar resultados mais precisos. O uso pode ficar limitado por aqui. Entre no site e baixe gratís e sem limite. <a href="https://www.pexels.com/" target="_blank"><u>AQUI</u></a>. Obs: Lembre-se de editar as imagens antes de usar nos seus serviços.</p>
       </div>
        <br></br>
        <br></br>
        <div className="d-flex align-items-center justify-content-between">
            <h2 className="mb-4 mt-4 text-muted">Galeria de fotos </h2> 
            <a href="https://www.pexels.com/" target="_blank">
              <h2>
              <div className="logo">
                <img src={LogoPexels} style={{width: 130, marginRight: 10}} alt="..." />
              </div>
             </h2>
           </a>
            <div className="input-group input-group-alternative col-3 bg-white">
              <input
                className="form-control form-control-alternative shadow"
                value={searchTerm}
                onChange={handleSearchSubmit}
                id="input-status"
                placeholder="Digite um nome ou cnpj"
              />
              <div className="input-group-append">
                <span className="input-group-text bg-transparent">
                  <i className="fa fa-search"></i>
                </span>
              </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="row">
          {images?.map((image) => (
            <div className="col-3 imagem" style={{maxHeight: "300px"}} key={image.id}>
              <img
                src={image.src.medium}
                alt={image.photographer}
                style={{objectFit: "contain", height: "100%", width: "100%"}}
                onClick={() => handleDownload(image.src.original)}
              />
              
            </div>
          ))}
        </div>

    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center mt-5">
        {getPageNumbers().map((pageNumber) => (
          <li key={pageNumber} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
            <a href="#top" className="page-link" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</a>
          </li>
        ))}
      </ul>
    </nav>
    <br></br>
    <br></br>
    <div className="card p-4">
      <p>Todas as fotos e vídeos do <a href="https://www.pexels.com/" target="_blank">Pexels</a> podem ser baixados e usados gratuitamente. Saiba mais sobre a Licença em <a href="https://www.pexels.com/license/" target="_blank">Termos de uso</a>.</p>
      </div>
  </div>
  );
}