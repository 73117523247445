import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import api from '../../services/api';
import * as yup from "yup";
import { notification } from '../../services/rsuite';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { updateEstabelecimento } from '../../store/modules/estabelecimento/actions';

const RecuperarSenha = () => {
  const { form } = useSelector(state => state.estabelecimento);
  const dispatch = useDispatch();

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      }
    )
    );
  };

  const schema = yup.object().shape({
    email: yup.string('insira um email válido!').required('insira um email!').email('insira um email válido!'),
  })
  const onSubmit = async () =>{
    setEstabelecimento('saving', true)
    const { data: res } = await api.post("/auth/recuperarSenha", { email: form?.email });
     
    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops!', description: res.message});
      setEstabelecimento('saving', false)
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto!', description: res.message});
    setEstabelecimento('saving', false)
    return false;
  }
  
  const { register, handleSubmit, formState: { errors }  } = useForm({
    resolver: yupResolver(schema)
  })
  return (
    <Container className="mt--9 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7 mx-auto h-100 mt-5">
          <Card className="bg-secondary shadow border-0 mt-5">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={form.saving? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      className="form-control"
                      ref={register}
                      {...register("email", {
                        onChange:(e) => setEstabelecimento('email', e.target.value.toLowerCase())
                      })}
                    />
                  </InputGroup>
                  {
                    errors.email?.type &&
                    <small className="validacao">{errors?.email?.message}</small>
                  }
                </FormGroup>
                <div className="text-center">
                  {
                    form.saving? (
                        <Button className="m3-4" color="primary">
                          <Spinner size="sm"/>
                        </Button>
                      ): (
                        <Button className="m3-4" color="primary" type="submit">
                          Recuperar
                        </Button>
                      )
                  }
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link
                className="text-light"
                to="/login"
              >
                <small>Fazer login</small>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link
                className="text-light"
                to='/cadastro'
              >
                <small>Criar nova conta</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RecuperarSenha;